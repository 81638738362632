import { ApplicationAppBar } from "features/appBar";
import { InnerContent } from "./InnerContent";
import { OuterBox } from "./OuterBox";

export function AppPageStandard({
  children,
  userState,
  title,
  titleColor,
  gradientColorFrom,
  gradientColorTo,
  onlyShowPageHeaderOnMobile,
}) {
  return (
    <OuterBox>
      <ApplicationAppBar userState={userState} />
      <InnerContent
        userState={userState}
        title={title}
        titleColor={titleColor}
        gradientColorFrom={gradientColorFrom}
        gradientColorTo={gradientColorTo}
        onlyShowPageHeaderOnMobile={onlyShowPageHeaderOnMobile}
      >
        {children}
      </InnerContent>
    </OuterBox>
  );
}
