import { Box, Typography } from "@mui/material";
import DiffMatchPatch from "diff-match-patch";

export function TitleChange({ dt, oldTitle, newTitle, isLatest }) {
  const dmp = new DiffMatchPatch();

  // Calculate the diff between old and new titles
  const diff = dmp.diff_main(oldTitle, newTitle);
  dmp.diff_cleanupSemantic(diff);

  // Render the diff
  const renderDiff = () => {
    return diff.map(([operation, text], index) => {
      if (operation === 1) {
        // Text added
        return (
          <Typography variant="body4" component="span" key={index} sx={{ backgroundColor: "lightgreen" }}>
            {text}
          </Typography>
        );
      } else if (operation === -1) {
        // Text removed
        return (
          <Typography
            component="span"
            variant="body4"
            key={index}
            sx={{ backgroundColor: "lightcoral", textDecoration: "line-through" }}
          >
            {text}
          </Typography>
        );
      } else {
        // Text unchanged
        return (
          <Typography variant="body4" component="span" key={index}>
            {text}
          </Typography>
        );
      }
    });
  };

  return (
    <Box>
      {/* Tooltip for showing the date of the change as a string */}
      <Typography variant="body4" sx={{ fontWeight: "bold" }}>
        {"" +
          (isLatest ? "Latest Title (" : "Title Change (") +
          new Date(dt).toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }) +
          ")"}
      </Typography>
      <Typography variant="body4">{renderDiff()}</Typography>
    </Box>
  );
}
