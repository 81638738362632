import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// First create a theme which includes properties that are not dependent on other properties
let theme = createTheme({
  // The breakpoints for responsive layouts (these are the builder.io breakpoints)
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 641,
      smallDesktop: 992,
      mediumDesktop: 1400,
      largeDesktop: 1920,
      veryLargeDesktop: 2560,
    },
  },

  // Layering of components
  zIndex: {
    drawerBelowAppBar: 900,
    appBar: 1000,
    drawerAboveAppBar: 1100,
  },

  // The colors that we are using throught the app
  palette: {
    // React colors
    primary: {
      main: "rgb(19, 116, 224)",
    },
    secondary: {
      main: "rgb(0, 235, 223)",
    },

    // Corporate identity colors
    ci: {
      dark: "rgb(10, 46, 179)",
      semiDark: "rgb(19, 116, 224)",
      neutral: "rgb(94, 162, 246)",
      semiLight: "rgb(42, 219, 253)",
      light: "rgb(0, 235, 223)",
    },
    black: {
      main: "rgb(0, 0, 0)",
    },
    white: {
      main: "rgb(255, 255, 255)",
    },
    grey: {
      light: "rgb(245, 245, 245)",
      main: "rgb(196, 196, 196)",
      dark: "rgb(117, 117, 117)",
    },
    red: {
      main: "rgb(207, 59, 52)",
    },

    // Performance score colors
    performanceScore: {
      _90_100: "rgb(0, 104, 55)",
      _80_90: "rgb(27, 152, 80)",
      _70_80: "rgb(101, 189, 99)",
      _60_70: "rgb(167, 217, 106)",
      _50_60: "rgb(217, 239, 139)",
      _40_50: "rgb(254, 224, 139)",
      _30_40: "rgb(254, 175, 97)",
      _20_30: "rgb(244, 109, 67)",
      _10_20: "rgb(215, 48, 39)",
      _0_10: "rgb(165, 0, 38)",
    },

    // Other colors
    error: {
      main: red.A400,
    },
    selected: {
      main: "rgba(0, 0, 0, 0.04)",
    },
  },
});

// The font family that will be used. When rendering text, the browser will go through this list and use the first
// font that is available on the user's system.
const fontFamily = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

// Thene add the rest of the theme properties
theme = createTheme(theme, {
  typography: {
    h1: {
      fontSize: 34,
      fontWeight: 700,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 42,
      },
    },
    h2: {
      fontFamily: fontFamily,
      fontSize: 28,
      fontWeight: 700,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 32,
      },
    },
    h3: {
      fontFamily: fontFamily,
      fontSize: 24,
      fontWeight: 700,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 28,
      },
    },
    h4: {
      fontFamily: fontFamily,
      fontSize: 22,
      fontWeight: 700,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 24,
      },
    },
    h5: {
      fontFamily: fontFamily,
      fontSize: 20,
      fontWeight: 700,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 22,
      },
    },
    h6: {
      fontFamily: fontFamily,
      fontSize: 18,
      fontWeight: 700,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 20,
      },
    },
    subtitle1: {
      fontFamily: fontFamily,
      fontSize: 16,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 18,
      },
      textTransform: "uppercase",
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: fontFamily,
      fontSize: 14,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 16,
      },
      textTransform: "uppercase",
      fontWeight: 500,
    },
    subtitle3: {
      fontFamily: fontFamily,
      fontSize: 12,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 14,
      },
      textTransform: "uppercase",
      fontWeight: 500,
    },
    body1: {
      fontFamily: fontFamily,
      lineHeight: 1.7,
      fontSize: 16,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 18,
      },
    },
    body2: {
      fontFamily: fontFamily,
      lineHeight: 1.5,
      fontSize: 14,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 16,
      },
    },
    body3: {
      fontFamily: fontFamily,
      fontSize: 13,
      lineHeight: 1.5,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 14,
      },
    },
    body4: {
      fontFamily: fontFamily,
      lineHeight: 1.5,
      fontSize: 12,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 13,
      },
    },
    button: {
      fontFamily: fontFamily,
      fontSize: 14,
      fontWeight: 500,
      textTransform: "none",
      [theme.breakpoints.up("tablet")]: {
        fontSize: 16,
      },
    },
    custom: { fontFamily: fontFamily },
  },
  // Mapping the typography variants to the correct HTML elements for SEO purposes
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "div",
          subtitle2: "div",
          subtitle3: "div",
          body1: "p",
          body2: "p",
          body3: "p",
          body4: "p",
          button: "div",
        },
      },
    },
  },
});

export default theme;
