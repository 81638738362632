import { Box } from "@mui/system";
import LogoBlack from "assets/logos/glowAI_icon_black_and_logo_black.svg";
import LogoWhite from "assets/logos/glowAI_icon_white_and_logo_white.svg";
import { Link } from "react-router-dom";

export function Logo({ color }) {
  const logoToDisplay = color === "black" ? LogoBlack : color === "white" ? LogoWhite : null;

  return (
    logoToDisplay && (
      <Link to="/" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box
          component={"img"}
          src={logoToDisplay}
          alt="glowAI Logo"
          sx={{ height: "25px", display: "flex", alignItems: "center", justifyContent: "center" }}
        />
      </Link>
    )
  );
}
