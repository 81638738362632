import { functions } from "config/firebase";
import { httpsCallable } from "firebase/functions";

export const liveQuery = httpsCallable(functions, "live-search-videos", {
  region: process.env.REACT_APP_CLOUD_FUNCTION_REGION,
});

export const getVideoScores = httpsCallable(functions, "live-search-channels", {
  region: process.env.REACT_APP_CLOUD_FUNCTION_REGION,
});

export const getElementDetails = httpsCallable(functions, "get-element-details", {
  region: process.env.REACT_APP_CLOUD_FUNCTION_REGION,
});
