import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoginIcon from "@mui/icons-material/Login";
import { Button, IconButton, Modal, Typography } from "@mui/material";
import { SignInBox } from "features/authentication";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsDesktop } from "styles/breakpoint";
import theme from "styles/theme";

export function SignInButton({
  backgroundColor = theme.palette.primary.main,
  backgroundColorHover = "rgba(48, 111, 219, 0.8)",
  fontColor = theme.palette.white.main,
  openPopupInsteadOfLink = false,
  alwaysLargeButton = false,
}) {
  // Local state
  const [openAuthPopup, setOpenAuthPopup] = useState(false);

  // Breakpoint
  const isDesktop = useIsDesktop();

  // Navigation
  const navigate = useNavigate();

  // Adjust link vs popup logic depending on the desired behavior
  const onClick = openPopupInsteadOfLink ? () => setOpenAuthPopup(true) : () => navigate("/auth");

  const handleClose = () => {
    setOpenAuthPopup(false);
  };

  return (
    <>
      {isDesktop || alwaysLargeButton ? (
        <Button
          variant="outlined"
          endIcon={<KeyboardArrowRightIcon sx={{ color: fontColor }} />}
          onClick={onClick}
          sx={{
            bgcolor: backgroundColor,
            borderRadius: 50,
            textTransform: "none",
            border: 0,
            height: 34,
            minWidth: { mobile: 0, tablet: 110 },
            "&:hover": {
              bgcolor: backgroundColorHover,
              border: 0,
            },
          }}
        >
          <Typography
            variant="button"
            sx={{ color: fontColor, display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            Sign In
          </Typography>
        </Button>
      ) : (
        <IconButton
          variant="outlined"
          onClick={onClick}
          sx={{
            bgcolor: backgroundColor,
            borderRadius: 50,
            textTransform: "none",
            border: 0,
            "&:hover": {
              bgcolor: backgroundColorHover,
              border: 0,
            },
            width: { mobile: 30, tablet: 40 },
            height: { mobile: 30, tablet: 40 },
          }}
        >
          <LoginIcon
            sx={{ color: fontColor, width: { mobile: 15, tablet: 20 }, height: { mobile: 15, tablet: 20 } }}
          />
        </IconButton>
      )}

      <Modal
        open={openAuthPopup}
        onClose={handleClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <>
          <SignInBox
            headerText="Sign in - it's free!"
            linkToAfterSignIn={null}
            functionToExecuteAfterSignIn={() => {
              setOpenAuthPopup(false);
            }}
          />
        </>
      </Modal>
    </>
  );
}
