import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function LinkSection({ text, textColor, destination }) {
  return (
    <Link to={destination}>
      <Button
        sx={{
          textTransform: "none",
          color: textColor,
          marginLeft: 2,
          marginRight: 2,
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        <Typography variant="body2" component="div">
          {text}
        </Typography>
      </Button>
    </Link>
  );
}
