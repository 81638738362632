import { Stack } from "@mui/material";
import { useIsDesktop } from "styles/breakpoint";
import { FiltersButton } from "./FiltersButton";
import { SearchTextField } from "./SearchTextField";

export function SearchInput() {
  const isDesktop = useIsDesktop();
  return (
    <Stack direction="row" spacing={isDesktop ? 1 : 0} sx={{ width: 600, alignItems: "center" }}>
      <SearchTextField />
      <FiltersButton />
    </Stack>
  );
}
