import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { SignInBox } from "features/authentication";
import { convertVideoLength } from "features/outliers/utils/formatting";
import { redirectToYouTubeVideo } from "features/outliers/utils/redirect";
import { useRef, useState } from "react";
import { useIsMobile } from "styles/breakpoint";
import theme from "styles/theme";
import { thumbnailStyles, titleBelowThumbnailStyles } from "styles/thumbnail";
import { useOutliersContext } from "../../OutliersContext";
import { accordionStyles, accordionSummaryStyles } from "../styles/detailsAccordion";
import { ChannelStats } from "./ChannelStats";
import { VideoStats } from "./VideoStats";

export function DetailsDrawer({ previewData, fetchedVideoData, fetchedChannelData, userState }) {
  // Breakpoints
  const isMobile = useIsMobile();

  // Context
  const { favorites, addToFavorites, removeFromFavorites, fetchFavoritesFromFirestore } = useOutliersContext();

  const favorite = favorites.includes(previewData.id);

  const [openAuthPopup, setOpenAuthPopup] = useState(false);
  const [favoriteTooltipText, setFavoriteTooltipText] = useState(
    favorite ? "Remove from favorites" : "Save to favorites",
  );
  const [thumbnailIsHovered, setThumbnailIsHovered] = useState(false);

  // Ref to store the timeout ID for the favorite tooltip
  const favoriteTimeoutRef = useRef(null); // Ref to store the timeout ID

  const handleClose = () => {
    setOpenAuthPopup(false);
  };

  const setFavorite = () => {
    if (userState.authenticated) {
      if (favorite) {
        setFavoriteTooltipText(null);
        removeFromFavorites(previewData.id);
      } else {
        setFavoriteTooltipText(null);
        addToFavorites(previewData);
      }

      // Clear any existing timeout to prevent old tooltips from showing up
      if (favoriteTimeoutRef.current) {
        clearTimeout(favoriteTimeoutRef.current);
      }

      // Set a new timeout and store its ID in the ref
      favoriteTimeoutRef.current = setTimeout(() => {
        // Revert tooltip text back to original after 3 seconds
        setFavoriteTooltipText(favorite ? "Save to favorites" : "Remove from favorites");
      }, 3000);
    } else {
      setOpenAuthPopup(true);
    }
  };

  // Render
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flexGrow: 1,
          justifyContent: "start",
          overflowY: "auto",
        }}
      >
        {/* Video Thumbnail with Title*/}
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
          <Box
            position={"relative"}
            onMouseEnter={() => setThumbnailIsHovered(true)}
            onMouseLeave={() => setThumbnailIsHovered(false)}
            sx={{ cursor: "pointer" }}
          >
            {/* Thumbnail Image */}
            <Box
              component="img"
              src={`https://i.ytimg.com/vi/${previewData.id}/mqdefault.jpg`}
              sx={{
                ...thumbnailStyles.sx,
                width: "100%",
                boxShadow: 1,
              }}
              alt="No image"
              onClick={(e) => {
                redirectToYouTubeVideo(previewData);
              }}
            />
            {/* Favorite marker */}
            <Box
              sx={{
                position: "absolute",
                top: "5px",
                right: "5px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 40,
                height: 40,
                ...((thumbnailIsHovered || isMobile) && {
                  background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
                }),
                ...(!thumbnailIsHovered &&
                  !isMobile && {
                    background: null,
                  }),
              }}
            >
              {favorite && (
                <Tooltip title={favoriteTooltipText}>
                  <FavoriteIcon
                    onClick={() => setFavorite()}
                    sx={{ color: theme.palette.red.main, cursor: "pointer" }}
                  />
                </Tooltip>
              )}
              {(thumbnailIsHovered || isMobile) && !favorite && (
                <Tooltip title={favoriteTooltipText}>
                  <FavoriteBorderIcon
                    onClick={() => setFavorite()}
                    sx={{ color: theme.palette.white.main, cursor: "pointer" }}
                  />
                </Tooltip>
              )}
            </Box>
            {/* Video Duration */}
            <Box
              sx={{
                position: "absolute",
                bottom: "13px",
                right: "5px",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: theme.palette.white.main,
                borderRadius: "5px",
                px: "5px",
                fontWeight: 700,
                py: 0,
              }}
            >
              <Typography variant="body4" sx={{ fontWeight: 700 }}>
                {convertVideoLength(previewData.duration)}
              </Typography>
            </Box>
          </Box>

          {/* Video Title */}
          <Typography
            variant={isMobile ? "body1" : "body2"}
            sx={{ ...titleBelowThumbnailStyles.sx }}
            onClick={(e) => {
              redirectToYouTubeVideo(previewData);
            }}
          >
            {previewData.title}
          </Typography>
        </Box>

        <Divider sx={{ mt: 2, mb: isMobile ? 0.5 : 2 }} flexItem />

        {/* Video Details */}

        {isMobile ? (
          /* Mobile View as Accordion */
          <Accordion sx={{ ...accordionStyles.sx }} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ ...accordionSummaryStyles.sx }}>
              Video Info
            </AccordionSummary>
            <AccordionDetails sx={{ display: "flex", flexDirection: "column", p: 0 }}>
              <VideoStats preview={previewData} latest={fetchedVideoData.latest} />
            </AccordionDetails>
          </Accordion>
        ) : (
          /* Desktop View as List */
          <VideoStats preview={previewData} latest={fetchedVideoData.latest} />
        )}

        {!isMobile && <Divider sx={{ my: 2 }} flexItem />}

        {isMobile ? (
          /* Mobile View as Accordion */
          <Accordion sx={{ ...accordionStyles.sx }} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ ...accordionSummaryStyles.sx }}>
              Channel Info
            </AccordionSummary>
            <AccordionDetails sx={{ display: "flex", flexDirection: "column", p: 0 }}>
              <ChannelStats preview={previewData} latest={fetchedChannelData.latest} />
            </AccordionDetails>
          </Accordion>
        ) : (
          <ChannelStats preview={previewData} latest={fetchedChannelData.latest} />
        )}
      </Box>
      <Modal
        open={openAuthPopup}
        onClose={handleClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <>
          <SignInBox
            headerText="Sign in to save this thumbnail to your favorites - it's free!"
            linkToAfterSignIn={null}
            functionToExecuteAfterSignIn={async () => {
              await fetchFavoritesFromFirestore();
              addToFavorites(previewData);
              setOpenAuthPopup(false);
            }}
          />
        </>
      </Modal>
    </>
  );
}
