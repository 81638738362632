import { Chip, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import theme from "styles/theme";

export default function CopyableChip({ tag }) {
  const [tooltipTitle, setTooltipTitle] = useState("Copy");

  // Function to handle the copy to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // Change the tooltip to "Copied" when the text is copied
      setTooltipTitle("Copied");
    });
  };

  const handleMouseLeave = () => {
    // wait for 0.1 seconds before changing the tooltip back to "Copy"
    setTimeout(() => {
      setTooltipTitle("Copy");
    }, 100);
  };

  return (
    <Tooltip title={tooltipTitle} arrow>
      <Chip
        key={tag}
        label={
          <Typography variant="body4" fontWeight={300}>
            {tag}
          </Typography>
        }
        size="small"
        sx={{
          mr: 0.2,
          my: 0.1,
          cursor: "pointer",
          backgroundColor: theme.palette.white.main,
          borderColor: "divider",
          border: "0.5px solid",
        }}
        onClick={() => handleCopy(tag)}
        onMouseLeave={handleMouseLeave}
      />
    </Tooltip>
  );
}
