import { TransparentLandingAppBar } from "features/appBar";
import { BuilderPage } from "features/builder";
import { Footer, LegalLinks } from "features/footer";
import { CustomHelmet } from "features/helmet";
import { Faq, SearchBar, ThumbnailGrid } from "features/landing";

const customComponents = [
  {
    component: TransparentLandingAppBar,
    name: "App Bar Landing",
  },
  {
    component: Footer,
    name: "Footer",
  },
  {
    component: Faq,
    name: "FAQ",
    inputs: [
      {
        name: "question",
        type: "longText",
        required: true,
      },
      {
        name: "answer",
        type: "richText",
        required: true,
      },
    ],
  },
  {
    component: LegalLinks,
    name: "Legal Links",
  },
  {
    component: SearchBar,
    name: "Search Bar",
  },
  {
    component: ThumbnailGrid,
    name: "Thumbnail Grid",
  },
];

export default function LandingPage() {
  return (
    <>
      <CustomHelmet />
      <BuilderPage customComponents={customComponents} />
    </>
  );
}
