import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { AppBar, Avatar, Box, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import { default as LogoLarge } from "assets/logos/glowAI_icon_color_and_logo_black.svg";
import { useAppContext } from "components/appContext/AppContext";
import { SignInButton } from "components/signIn/SignInButton";
import { auth } from "config/firebase";
import { useEffect, useState } from "react";
import { useIsMobile } from "styles/breakpoint";
import { APP_BAR_HEIGHT } from "styles/layout";
import theme from "styles/theme";
import { UserMenu } from "./UserMenu";

export function ApplicationAppBar({ userState, children, showLogo = true }) {
  // App context
  const { setMobileApplicationDrawerOpen } = useAppContext();

  // Local state
  const [scrollAtTop, setScorllAtTop] = useState(true);

  // Breakpoint
  const isMobile = useIsMobile();

  // User menu open and close logic
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  // Check if user is authenticated
  const isAuthenticated = userState.authenticated;

  // Scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScorllAtTop(false);
      } else {
        setScorllAtTop(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: isMobile && scrollAtTop ? "transparent" : theme.palette.white.main,
          boxShadow: "none",
          width: "100%",
          zIndex: theme.zIndex.appBar,
          transition: "background-color 0.1s ease",
        }}
      >
        <Toolbar sx={{ alignItems: "center", width: "100%", display: "flex", minHeight: APP_BAR_HEIGHT }}>
          <Stack
            direction={"row"}
            width={"100%"}
            spacing={1}
            alignItems={"center"}
            alignContent={"center"}
            justifyContent={isMobile && scrollAtTop ? "flex-end" : "space-between"}
          >
            {/* Logo on the left side of the app bar */}
            {(!isMobile || (isMobile && !scrollAtTop && showLogo)) && (
              <Box
                href="/app"
                sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                onClick={(event) => {
                  event.preventDefault();
                  // Force page reload to bring user from search page to overview page and trigger default search
                  window.location.href = "/";
                }}
              >
                <Box
                  component={"img"}
                  src={LogoLarge}
                  alt="glowAI"
                  sx={{ height: { mobile: 20, tablet: 25 }, alignSelf: "center" }}
                />
              </Box>
            )}

            {/* Passed in children (i.e. search bar) in the middle of the app bar */}
            {children}

            {/* User icon or sign in button on the right side of the app bar */}
            {!isMobile &&
              (isAuthenticated ? (
                <Avatar
                  onClick={handleClick}
                  sx={{
                    height: { mobile: 30, tablet: 40 },
                    width: { mobile: 30, tablet: 40 },
                    boxShadow: 0,
                    color: theme.palette.white.main,
                    bgcolor: "primary.main",
                    cursor: "pointer",
                    transition: "box-shadow 0.1s ease-in-out",
                    "&:hover": {
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                    },
                  }}
                >
                  <Typography variant="button">{auth.currentUser.displayName[0]}</Typography>
                </Avatar>
              ) : (
                <SignInButton alwaysLargeButton={true} openPopupInsteadOfLink={true} />
              ))}
            {isMobile && (
              <IconButton onClick={() => setMobileApplicationDrawerOpen(true)} sx={{ p: 0 }}>
                <MenuOutlinedIcon
                  sx={{
                    color: scrollAtTop ? theme.palette.white.main : theme.palette.grey.dark,
                    borderRadius: 10,
                    height: 35,
                    width: 35,
                    px: 0.6,
                  }}
                />
              </IconButton>
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      {/* User menu which opens by click on user icon */}
      <UserMenu anchorEl={anchorEl} handleClose={handleCloseUserMenu} />
    </>
  );
}
