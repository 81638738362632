import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import CloseIcon from "@mui/icons-material/Close";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import { Box, Dialog, DialogContent, Divider, IconButton, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useIsMobile, useIsTablet } from "styles/breakpoint";
import theme from "styles/theme";
import { getElementDetails } from "../../api/cloudFunctions";
import { useOutliersContext } from "../OutliersContext";
import { ChangesTab } from "./ChangesTab/ChangesTab";
import { DetailsDrawer } from "./DetailsDrawer/DetailsDrawer";
import { DetailsTab } from "./DetailsTab/DetailsTab";
import { SimilarThumbnailsTab } from "./SimilarThumbnailsTab/SimilarThumbnailsTab";

export function VideoPopup({ userState }) {
  // Context
  const {
    selectedVideo,
    setSelectedVideo,
    openVideoPopup,
    setOpenVideoPopup,
    closeVideoPopup,
    selectedVideoTab,
    setSelectedVideoTab,
    fetchedVideoDetails,
    setFetchedVideoDetails,
    fetchedChannelDetails,
    setFetchedChannelDetails,
    setSimilarThumbnailInitialLoad,
  } = useOutliersContext();

  // Breakpoints
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  // Location
  const location = useLocation();

  // useEffect (Fetch Video and Channel Details when Video Popup is opened and selectedVideo is set)
  useEffect(() => {
    if (openVideoPopup && selectedVideo) {
      getElementDetails({
        id: selectedVideo.id,
        type: "video",
        history: ["view_count", "like_count", "comment_count", "title", "tags"],
      }).then((result) => {
        setFetchedVideoDetails(result.data);
      });
      getElementDetails({ id: selectedVideo.custom_url, type: "channel" }).then((result) => {
        setFetchedChannelDetails(result.data);
      });
    }
  }, [openVideoPopup, selectedVideo]);

  // useEffect (Check if Dialog is open with video preview)
  useEffect(() => {
    if (location.state?.openVideoPopup) {
      setSelectedVideo(location.state.selectedVideo);
      setFetchedVideoDetails({ latest: {}, history: {} });
      setFetchedChannelDetails({ latest: {}, history: {} });
      setSimilarThumbnailInitialLoad(true);
      setSelectedVideoTab(location.state.tabIndex);
      setOpenVideoPopup(location.state.openVideoPopup);
    } else {
      setOpenVideoPopup(false);
    }
  }, [location.state]);

  // Event Handlers
  const handleTabChange = (_, newIndex) => {
    if (newIndex === 2) {
      // Set Similar Thumbnails Initial Load to true when switching to Similar Thumbnails Tab
      setSimilarThumbnailInitialLoad(true);
    }
    setSelectedVideoTab(newIndex);
  };

  // Render
  return (
    <Dialog
      open={openVideoPopup}
      onClose={closeVideoPopup}
      fullWidth
      sx={{ algin: "center" }}
      PaperProps={{
        sx: { height: "90%", width: { mobile: "90%", tablet: "85%" }, borderRadius: 5 },
      }}
    >
      <DialogContent sx={{ p: 0, height: "100%", mt: isMobile ? 5 : 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "top",
            alignItems: "center",
            height: "100%",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {/* Left Box with Details Drawer */}
          <Box
            component={Paper}
            sx={{
              minWidth: 200,
              width: { mobile: "100%", tablet: "40%", smallDesktop: "30%", mediumDesktop: "25%" },
              height: "100%",
              p: 2,
              m: 0,
              borderRadius: 0,
            }}
          >
            <DetailsDrawer
              userState={userState}
              previewData={selectedVideo}
              fetchedVideoData={fetchedVideoDetails}
              fetchedChannelData={fetchedChannelDetails}
            />
          </Box>

          <Divider orientation="vertical" flexItem />

          {/* Right Box with Tab-based Content */}
          <Box
            sx={{
              display: "flex",
              minWidth: 200,
              width: { mobile: "100%", tablet: "60%", smallDesktop: "70%", mediumDesktop: "75%" },
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Tabs
                value={selectedVideoTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  "& .MuiTab-root": { typography: "body3" },
                }}
              >
                <Tab
                  label={
                    isMobile || isTablet ? (
                      <VideoSettingsIcon sx={{ mt: 0.5 }} />
                    ) : (
                      <Typography variant="body3">Details</Typography>
                    )
                  }
                  sx={{ p: 0, m: 0 }}
                />
                <Tab
                  label={
                    isMobile || isTablet ? (
                      <AltRouteIcon sx={{ mt: 0.5 }} />
                    ) : (
                      <Typography variant="body3">Changes</Typography>
                    )
                  }
                  sx={{ p: 0, m: 0 }}
                />
                <Tab
                  label={
                    isMobile || isTablet ? (
                      <AddToPhotosIcon sx={{ mt: 0.5 }} />
                    ) : (
                      <Typography variant="body3">Similar Thumbnails</Typography>
                    )
                  }
                  sx={{ p: 0, m: 0 }}
                />
              </Tabs>

              <Box maxHeight={"calc(100% - 49px)"} overflow="auto">
                {selectedVideoTab === 0 && (
                  <>
                    {/* Details Tab */}
                    <DetailsTab
                      previewData={selectedVideo}
                      fetchedVideoData={{
                        latest: fetchedVideoDetails.latest,
                        history: {
                          view_count: fetchedVideoDetails.history?.view_count || {},
                          like_count: fetchedVideoDetails.history?.like_count || {},
                          comment_count: fetchedVideoDetails.history?.comment_count || {},
                        },
                      }}
                      fetchedChannelData={fetchedChannelDetails}
                    />
                  </>
                )}
                {selectedVideoTab === 1 && (
                  <>
                    {/* Metadata Changes Tab */}
                    <ChangesTab
                      changes={{
                        title: {
                          ...(fetchedVideoDetails.history?.title || {}),
                          [fetchedVideoDetails.latest?.last_scrape]: fetchedVideoDetails.latest?.title || "",
                        },
                        tags: {
                          ...(fetchedVideoDetails.history?.tags || {}),
                          [fetchedVideoDetails.latest?.last_scrape]: fetchedVideoDetails.latest?.tags || "",
                        },
                      }}
                    />
                  </>
                )}
                {selectedVideoTab === 2 && (
                  <>
                    {/* Similar Thumbnails Tab */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <SimilarThumbnailsTab userState={userState} id={selectedVideo.id} />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <IconButton
        onClick={closeVideoPopup}
        sx={{
          position: "absolute",
          right: 8,
          top: 5,
          color: theme.palette.black.main,
        }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}
