import { Box, Typography } from "@mui/material";

export function PageHeader({ title, titleColor, gradientColorFrom, gradientColorTo }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: { mobile: "flex-end", tablet: "flex-end" },
        borderRadius: { mobile: 0, tablet: 2 },
        height: { mobile: "110px", tablet: "110px" },
        backgroundImage: `linear-gradient(to right, ${gradientColorFrom}, ${gradientColorTo})`,
        mt: { mobile: 0, tablet: 2 },
        mb: { mobile: 2, tablet: 4 },
      }}
    >
      <Typography variant="h3" component="h1" sx={{ m: { mobile: 2, tablet: 3 }, color: titleColor }}>
        {title}
      </Typography>
    </Box>
  );
}
