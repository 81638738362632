import AddCircleIcon from "@mui/icons-material/AddCircle";
import BugReportIcon from "@mui/icons-material/BugReport";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import MessageIcon from "@mui/icons-material/Message";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import { useState } from "react";
import theme from "styles/theme";
import { BugReportPopover } from "./BugReportPopover";
import { FeatureRequestPopover } from "./FeatureRequestPopover";
import { FeedbackProvider } from "./FeedbackContext";
import { GeneralFeedbackPopover } from "./GeneralFeedbackPopover";

export function FeedbackSpeedDial() {
  // Local state
  const [openBugReportPopover, setOpenBugReportPopover] = useState(false);
  const [openFeatureRequestPopover, setOpenFeatureRequestPopover] = useState(false);
  const [openGeneralFeedbackPopover, setOpenGeneralFeedbackPopover] = useState(false);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);

  return (
    <FeedbackProvider>
      <SpeedDial
        ariaLabel="customer feedback"
        sx={{
          position: "fixed",
          width: 64,
          bottom: { mobile: 8, tablet: 16 },
          right: { mobile: 8 },
          left: { tablet: 16 },
          zIndex: theme.zIndex.drawerBelowAppBar + 1,
        }}
        icon={<MapsUgcIcon />}
        open={openSpeedDial}
        onClick={() => setOpenSpeedDial(!openSpeedDial)}
        onClose={() => setOpenSpeedDial(false)}
        onMouseEnter={() => setOpenSpeedDial(true)}
        onMouseLeave={() => setOpenSpeedDial(false)}
        transitionDuration={0}
      >
        <SpeedDialAction
          key={"Bug Report"}
          icon={<BugReportIcon />}
          tooltipTitle={"Bug Report"}
          onClick={() => setOpenBugReportPopover(true)}
        />
        <SpeedDialAction
          key={"Feature Request"}
          icon={<AddCircleIcon />}
          tooltipTitle={"Feature Request"}
          onClick={() => setOpenFeatureRequestPopover(true)}
        />
        <SpeedDialAction
          key={"Feedback"}
          icon={<MessageIcon />}
          tooltipTitle={"Feedback"}
          onClick={() => setOpenGeneralFeedbackPopover(true)}
        />
      </SpeedDial>
      <BugReportPopover open={openBugReportPopover} setOpen={setOpenBugReportPopover} />
      <FeatureRequestPopover open={openFeatureRequestPopover} setOpen={setOpenFeatureRequestPopover} />
      <GeneralFeedbackPopover open={openGeneralFeedbackPopover} setOpen={setOpenGeneralFeedbackPopover} />
    </FeedbackProvider>
  );
}
