import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import { formatScore } from "features/outliers/utils/formatting";
import { redirectToYouTubeChannel } from "features/outliers/utils/redirect";
import { StatsRow } from "./StatsRow";

export function ChannelStats({ preview, latest }) {
  return (
    <Box sx={{ m: 0, p: 0 }}>
      {/* Channel Data with Image*/}
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        {latest.thumbnails_default_url ? (
          <Avatar
            src={latest.thumbnails_default_url}
            sx={{ width: 40, height: 40, mr: 1, cursor: "pointer" }}
            onClick={(e) => {
              redirectToYouTubeChannel(preview);
            }}
          />
        ) : (
          <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1 }} />
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body4">
            {preview.channel_title + " (" + formatScore(preview.subscriber_count) + " subscribers)"}
          </Typography>
          <Typography
            variant="body4"
            color="textSecondary"
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={(e) => {
              redirectToYouTubeChannel(preview);
            }}
          >
            {preview.custom_url}
          </Typography>
        </Box>
      </Box>
      {/* Channel Stats */}
      <StatsRow label="Total Videos" value={latest.video_count} formatter={formatScore} />
      <StatsRow label="Avg. Views / Video" value={preview.channel_med_views} formatter={formatScore} />
    </Box>
  );
}
