import { Box, Typography } from "@mui/material";
import { auth } from "config/firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "styles/theme";
import { signInUser } from "../api/signInUser";
import { GoogleButton } from "./GoogleButton";

export function SignInBox({
  headerText = "Welcome to glowAI",
  linkToAfterSignIn = "/app/outliers/search",
  functionToExecuteAfterSignIn = null,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.currentUser !== null) {
      navigate(linkToAfterSignIn);
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.white.main,
        borderRadius: "20px",
        boxShadow: 5,
        maxWidth: "420px",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        margin: 2,
      }}
    >
      <Typography variant="h4" sx={{ marginTop: "20px", textAlign: "center" }}>
        {headerText}
      </Typography>
      <Box sx={{ marginTop: "20px" }}>
        <GoogleButton
          text="Sign In with Google"
          onClick={() => signInUser(navigate, linkToAfterSignIn, functionToExecuteAfterSignIn)}
        />
      </Box>
      <Typography variant="body2" sx={{ marginTop: "20px", textAlign: "center" }}>
        © {new Date().getFullYear()} glowAI. All rights reserved.
      </Typography>
    </Box>
  );
}
