export const LANDING_LINKS = [
  {
    text: "Outlier Database",
    destination: "/",
  },
  {
    text: "Blog",
    destination: "/blog",
  },
];
