import { Box, Skeleton, Typography } from "@mui/material";
import { PerformanceScoreChip } from "components/performanceScore/PerformanceScoreChip";
import { useEffect, useState } from "react";

export function StatsRow({ label, value, formatter, suffix }) {
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    // Reset the displayValue when value changes
    setDisplayValue(value);

    // Set a timeout to show 'n/a' after 20 seconds if value is null
    const timer = setTimeout(() => {
      if (value === null) {
        setDisplayValue("n/a");
      }
    }, 20000);

    // Cleanup the timeout on component unmount or if value changes
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        mb: 0.5,
      }}
    >
      <Typography variant="body4" sx={{ fontWeight: 300, mr: 1 }}>
        {label}
      </Typography>
      {displayValue ? (
        displayValue !== "n/a" ? (
          label === "glowAI Score" ? (
            <PerformanceScoreChip score={displayValue} />
          ) : (
            <Typography variant="body4">{formatter(displayValue) + (suffix ? suffix : "")}</Typography>
          )
        ) : (
          <Typography variant="body4">n/a</Typography>
        )
      ) : (
        <Skeleton variant="text" width="30%" sx={{ height: 20 }} />
      )}
    </Box>
  );
}
