import { AppPageStandard } from "components/page";
import { CustomHelmet } from "features/helmet";
import { FavoritesGridWithVideoPopup, INDEX_NAME, OutliersProvider, REFRESH_MS, setupSearch } from "features/outliers";
import { useEffect, useState } from "react";
import { InstantSearch } from "react-instantsearch";
import theme from "styles/theme";

const TITLE = "Favorite Outliers";
const TITLE_COLOR = theme.palette.white.main;
const GRADIENT_COLOR_FROM = "rgb(232,51,35)";
const GRADIENT_COLOR_TO = "rgb(140,27,16)";
const ONLY_SHOW_PAGE_HEADER_ON_MOBILE = false;

export default function FavoritesPage({ userState }) {
  // Local state
  const [typesenseInstance, setTypesenseInstance] = useState({});
  const [retrievingKeys, setRetrievingKeys] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    // When loading the page for the first time, set up the search client
    if (initialLoad) {
      setupSearch(retrievingKeys, setRetrievingKeys, setTypesenseInstance, setInitialLoad);
    }

    // Refresh the search client every REFRESH_MS milliseconds to avoid the expiry of the typesense API key
    const interval = setInterval(() => {
      setupSearch(retrievingKeys, setRetrievingKeys, setTypesenseInstance, setInitialLoad);
    }, REFRESH_MS);

    return () => clearInterval(interval);
  }, [retrievingKeys, initialLoad]);

  return (
    <>
      <OutliersProvider>
        <CustomHelmet title={"glowAI Favorites"} index={false} follow={false} />
        {initialLoad ? (
          // While the search client is being set up, show the app bar and drawer
          <AppPageStandard
            userState={userState}
            title={TITLE}
            titleColor={TITLE_COLOR}
            gradientColorFrom={GRADIENT_COLOR_FROM}
            gradientColorTo={GRADIENT_COLOR_TO}
            onlyShowPageHeaderOnMobile={ONLY_SHOW_PAGE_HEADER_ON_MOBILE}
          />
        ) : (
          // Once the search client is set up, also show the search input and the search results grid
          <InstantSearch
            indexName={INDEX_NAME}
            searchClient={typesenseInstance.searchClient}
            future={{ preserveSharedStateOnUnmount: true }}
          >
            <AppPageStandard
              userState={userState}
              title={TITLE}
              titleColor={TITLE_COLOR}
              gradientColorFrom={GRADIENT_COLOR_FROM}
              gradientColorTo={GRADIENT_COLOR_TO}
              onlyShowPageHeaderOnMobile={ONLY_SHOW_PAGE_HEADER_ON_MOBILE}
            >
              <FavoritesGridWithVideoPopup userState={userState} />
            </AppPageStandard>
          </InstantSearch>
        )}
      </OutliersProvider>
    </>
  );
}
