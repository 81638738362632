import { HITS_PER_PAGE } from "./config";
import { MUST_HAVE_FILTER_STRING } from "./filters";

// Trigger a similar search in Typesense
export function triggerSimilarSearch(id, setIndexUiState) {
  window.scrollTo({ top: 0, left: 0, behavior: "instant" });

  setIndexUiState({
    query: "*",
    configure: {
      filters: MUST_HAVE_FILTER_STRING,
      hitsPerPage: HITS_PER_PAGE,
      typesenseVectorQuery: "embedding:([], id:" + id + ")",
      similarSearch: true,
      queryBy: "embedding",
    },
    page: 0,
    sortBy: "",
  });
}
