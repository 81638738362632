import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Drawer, Fade, IconButton, Stack, Typography } from "@mui/material";
import { SignInButton } from "components/signIn/SignInButton";
import { useNavigate } from "react-router-dom";
import { APP_BAR_HEIGHT } from "styles/layout";
import theme from "styles/theme";
import { LANDING_LINKS } from "./landingLinks";

const TRANSITION_DELAY = 500;

export function LandingMenu({
  drawerOpen,
  handleDrawerOpen,
  handleDrawerClose,
  menuIconColor = theme.palette.black.main,
}) {
  const navigate = useNavigate();

  const handleClick = (destination) => {
    handleDrawerClose();
    navigate(destination);
  };

  const transitionDelayItems = TRANSITION_DELAY / (LANDING_LINKS.length + 1);

  return (
    <>
      <IconButton onClick={handleDrawerOpen} sx={{ p: 0 }}>
        <MenuIcon sx={{ color: menuIconColor }} />
      </IconButton>

      <Drawer
        anchor="top"
        transitionDuration={{ enter: TRANSITION_DELAY, exit: TRANSITION_DELAY }}
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <Stack
          direction="column"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box>
            {/* Display close button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                pr: 2,
                height: APP_BAR_HEIGHT,
              }}
            >
              <Box>
                <IconButton onClick={handleDrawerClose} sx={{ p: 0 }}>
                  <CloseIcon sx={{ color: theme.palette.black.main }} />
                </IconButton>
              </Box>
            </Box>

            {/* Display menu items */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {LANDING_LINKS.map((link, index) => (
                <Fade in={true} style={{ transitionDelay: `${index * transitionDelayItems}ms` }} key={index}>
                  <Typography
                    variant="h2"
                    component="div"
                    onClick={() => handleClick(link.destination)}
                    sx={{ mt: 2, cursor: "pointer" }}
                  >
                    {link.text}
                  </Typography>
                </Fade>
              ))}
            </Box>
          </Box>

          {/* Display sign in button */}
          <Fade in={true} style={{ transitionDelay: `${LANDING_LINKS.length * transitionDelayItems}ms` }}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 8 }}>
              <SignInButton alwaysLargeButton={true} />
            </Box>
          </Fade>
        </Stack>
      </Drawer>
    </>
  );
}
