export function formatScore(score) {
  if (score >= 1000000000) {
    return (score / 1000000000).toFixed(0) + "B";
  } else if (score >= 10000000) {
    return (score / 1000000).toFixed(0) + "M";
  } else if (score >= 1000000) {
    return (score / 1000000).toFixed(1) + "M";
  } else if (score >= 5000) {
    return (score / 1000).toFixed(0) + "k";
  }
  return (score / 1).toFixed(0) + "";
}

export function calculateVideoAge(creationDate) {
  const now = new Date();
  const diffInMilliseconds = Math.abs(now.getTime() - creationDate * 1000);

  const minutes = Math.floor(diffInMilliseconds / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return `${years}y`;
  } else if (months > 0) {
    return `${months}mo`;
  } else if (days > 0) {
    return `${days}d`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else {
    return `${minutes}min`;
  }
}

export function convertVideoLength(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds - hours * 3600) / 60);
  const seconds = durationInSeconds - hours * 3600 - minutes * 60;

  let result = "";
  if (hours > 0) {
    result += hours.toString().padStart(2, "0") + ":";
  }
  result += minutes.toString().padStart(2, "0") + ":";
  result += seconds.toString().padStart(2, "0");

  return result;
}

export function generateRandomYouTubeVideoId() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
  let result = "";
  for (let i = 0; i < 11; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
