import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useAppContext } from "components/appContext/AppContext";
import { analytics, logEvent } from "config/firebase";
import { useEffect, useRef, useState } from "react";
import { useInstantSearch, useSearchBox } from "react-instantsearch";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "styles/breakpoint";
import theme from "styles/theme";
import { triggerRegularSearch } from "../api/typesense/regularSearch";
import { updateUrlParameters } from "../utils/url";
import { useOutliersContext } from "./OutliersContext";

export function SearchTextField() {
  // Search state
  useSearchBox(); // is a hook
  const { indexUiState, setIndexUiState } = useInstantSearch();

  // Search context state
  const { query, setQuery, selectedFiltersUrl, selectedFilters } = useOutliersContext();

  // App context state
  const { setLastOutliersSearchParameters } = useAppContext();

  // Local state
  const [userInput, setUserInput] = useState(query);
  const [scrollAtTop, setScorllAtTop] = useState(true);

  // Ref for the TextField component
  const textFieldRef = useRef(null);

  // Url
  const navigate = useNavigate();

  // Breakpoint
  const isMobile = useIsMobile();
  const placeholderText = isMobile ? "Enter search term ..." : "Enter a search term to find outliers ...";

  // This function is called when the search button is clicked
  function handleSearchClick(event) {
    // Only search if user has inputted something other than spaces
    if (userInput && userInput.trim() !== "") {
      // Prevent the page from refreshing
      event.preventDefault();

      // Log event
      logEvent(analytics, "search_app", {
        search_query: userInput,
      });

      // Trigger the search
      triggerRegularSearch(setIndexUiState, userInput, selectedFilters);

      // Jump to the top of the page
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });

      // Update context state
      setQuery(userInput);

      // Update URL
      updateUrlParameters(navigate, userInput, selectedFiltersUrl, setLastOutliersSearchParameters);
    }
  }

  // Function to clear the user input and focus the TextField
  function clearInput() {
    setQuery("");
    // Focus the TextField
    textFieldRef.current.focus();
  }

  // Update user input when query in the context changes (i.e. when the query is being fetched from the URL)
  useEffect(() => {
    setUserInput(query);
  }, [query]);

  // Scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScorllAtTop(false);
      } else {
        setScorllAtTop(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <TextField
      size="small"
      sx={{
        width: "100%",
        paddingLeft: { mobile: 0, tablet: 1 },
        paddingRight: 1,
        "& .MuiInputBase-input::placeholder": {
          ...theme.typography.body2,
        },
        "& .MuiInputBase-input": {
          ...theme.typography.body2,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: {
              mobile: scrollAtTop ? "none" : `1px solid ${theme.palette.grey.main}`,
              tablet: `1px solid ${theme.palette.grey.main}`,
            },
          },
        },
      }}
      inputRef={textFieldRef}
      InputProps={{
        sx: {
          borderRadius: { mobile: 10, tablet: 10 },
          borderWidth: 0,
          fontSize: 16,
          backgroundColor: theme.palette.white.main,
        },
        endAdornment: (
          <InputAdornment position="end">
            {userInput && (
              <IconButton
                sx={{ ":hover": { color: "primary.main", "&:hover": { backgroundColor: "transparent" } } }}
                onClick={clearInput}
              >
                <ClearIcon />
              </IconButton>
            )}
            <IconButton
              disabled={!userInput}
              sx={{
                ":hover": { color: userInput ? "primary.main" : "default" },
              }}
              onClick={(event) => handleSearchClick(event)}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder={placeholderText}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleSearchClick(event);
        }
      }}
      onChange={(e) => setUserInput(e.target.value)}
      value={userInput}
    />
  );
}
