import { Helmet } from "react-helmet";

export function CustomHelmet({
  title = "glowAI",
  description = "Discover the Best-Performing Videos on YouTube. Get Thumbnail and Title Inspirations. Find New Viral YouTube Video Ideas.",
  image = "/favicon-196x196.png",
  url = null,
  index = true,
  follow = true,
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:site_name" content="glowAI" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url === null ? window.location.href : url} />
      <meta name="robots" content={`${index ? "index" : "noindex"}, ${follow ? "follow" : "nofollow"}`} />
      <meta name="twitter:card" content={"summary_large_image"} />
      <meta name="twitter:site" content={"@glowlabsAI"} />
      <meta name="twitter:creator" content={"@glowlabsAI"} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:src" content={image} />
    </Helmet>
  );
}
