import { AppBar, Box, Toolbar } from "@mui/material";
import { SignInButton } from "components/signIn/SignInButton";
import { useState } from "react";
import { useIsDesktop, useIsTablet } from "styles/breakpoint";
import { APP_BAR_HEIGHT, MAX_SECTION_WIDTH } from "styles/layout";
import theme from "styles/theme";
import { BetaIcon } from "../BetaIcon";
import { Logo } from "../Logo";
import { LandingMenu } from "./LandingMenu";
import { LinkSection } from "./LinkSection";
import { LANDING_LINKS } from "./landingLinks";

export function WhiteLandingAppBar() {
  // Local state
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Breakpoint
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();

  // Event handlers
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        justifyContent: "center",
        display: "flex",
        bgcolor: theme.palette.white.main,
        pb: 4,
      }}
    >
      <AppBar
        position={"fixed"}
        sx={{
          zIndex: theme.zIndex.appBar,
          bgcolor: theme.palette.white.main,
          boxShadow: "none",
          width: "100%",
          maxWidth: MAX_SECTION_WIDTH,
          margin: "auto",
          left: 0,
          right: 0,
          px: 2,
        }}
      >
        <Toolbar
          sx={{ alignItems: "center", justifyContent: "space-between", width: "100%", p: 0, height: APP_BAR_HEIGHT }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            {/* Display Logo */}
            <Logo color={"black"} />
            {(isTablet || isDesktop) && <BetaIcon />}

            {/* Display Links */}
            {isDesktop && (
              <Box sx={{ marginLeft: "40px" }}>
                {LANDING_LINKS.map((link, index) => (
                  <LinkSection
                    key={index}
                    text={link.text}
                    textColor={theme.palette.black.main}
                    destination={link.destination}
                  />
                ))}
              </Box>
            )}
          </Box>

          {/* Display Sign In */}
          {isDesktop ? (
            <SignInButton />
          ) : (
            <LandingMenu
              drawerOpen={drawerOpen}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
            />
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{ paddingTop: "56px" }} />
    </Box>
  );
}
