// Page imports
import AuthenticationPage from "pages/AuthenticationPage";
import BlogArticlePage from "pages/BlogArticlePage";
import BlogPage from "pages/BlogPage";
import FavoritesPage from "pages/FavoritesPage";
import ImprintPage from "pages/ImprintPage";
import LandingPage from "pages/LandingPage";
import OutliersPage from "pages/OutliersPage";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage";
import TermsOfServicePage from "pages/TermsOfServicePage";

// Component imports
import { GoogleOAuthProvider } from "@react-oauth/google";
import { registerCustomBuilderComponents } from "api/builder";
import { createAuthStateListener } from "api/createAuthStateListener";
import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// Register custom Builder components
registerCustomBuilderComponents();

// App component
export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [userState, setUserState] = useState({
    authenticated: false,
    developer: false,
    betaUser: false,
    customer: false,
  });

  useEffect(() => {
    const unsubscribe = createAuthStateListener(setIsLoading, setUserState);
    return () => unsubscribe();
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CONSENT_CLIENT_ID}>
      <BrowserRouter>
        <Routes>
          {/* Paths everyone can access */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/legal/terms" element={<TermsOfServicePage />} />
          <Route path="/legal/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/legal/imprint" element={<ImprintPage />} />
          <Route path="/auth" element={<AuthenticationPage />} />
          <Route path="/app/outliers/search" element={<OutliersPage userState={userState} />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:handle" element={<BlogArticlePage />} />

          {/* Paths you can access if you are signed in */}
          {userState.authenticated && (
            <>
              <Route path="/app/outliers/favorites" element={<FavoritesPage userState={userState} />} />
            </>
          )}

          {/* Paths you can access if you are signed in and are a developer */}
          {userState.authenticated && userState.developer && <></>}

          {/* Redirect all other paths to the landing page */}
          {!isLoading && <Route path="*" element={<Navigate to="/" />} />}
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}
