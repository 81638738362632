import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { analytics, logEvent } from "config/firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "styles/theme";
import { calculateReadTime, formatDate } from "../utils/formatting";
import { ArticleThumbnail } from "./ArticleThumbnail";

export function ArticlePreview({ data, currentHandle = null }) {
  const formattedDate = formatDate(data.date);
  const readTime = calculateReadTime(data.blocks);
  const navigate = useNavigate();

  // Scroll to top on mount
  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  // Navigate to other blog article
  const handleClick = () => {
    if (currentHandle) {
      logEvent(analytics, "navigate_to_other_blog_article", {
        from_article_handle: currentHandle,
        to_article_handle: data.handle,
      });
    }
    navigate(`/blog/${data.handle}`);
  };

  return (
    <Grid container spacing={1} onClick={handleClick} sx={{ cursor: "pointer" }}>
      {/* Thumbnail */}
      <Grid mobile={12}>
        <ArticleThumbnail image={data.image} title={data.title} />
      </Grid>

      <Grid mobile={12}>
        <Stack direction="row" spacing={3} sx={{ pt: 0.5 }}>
          {/* Category */}
          <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main }}>
            {data.category}
          </Typography>

          {/* Read time */}
          <Typography variant="subtitle2" sx={{ color: theme.palette.grey.dark }}>
            {readTime} MIN
          </Typography>
        </Stack>
      </Grid>

      {/* Title */}
      <Grid mobile={12}>
        <Typography variant="h2" component="div">
          {data.title}
        </Typography>
      </Grid>

      <Grid mobile={12}>
        <Stack direction="column" spacing={0}>
          {/* Author */}
          <Typography variant="subtitle2" sx={{ color: theme.palette.grey.dark }}>
            WRITTEN BY {data.author}
          </Typography>

          {/* Date */}
          <Typography variant="subtitle2" sx={{ color: theme.palette.grey.dark }}>
            {formattedDate}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
