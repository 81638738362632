import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkIcon from "@mui/icons-material/Link";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { analytics, logEvent } from "config/firebase";
import { useState } from "react";
import theme from "styles/theme";

const sharedStyle = {
  borderWidth: 1,
  borderStyle: "solid",
  color: theme.palette.black.main,
  backgroundColor: "transparent",
  borderColor: theme.palette.grey.main,
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    borderColor: theme.palette.grey.main,
  },
};

export function ShareOnSocialMediaButtons({ title }) {
  // Local state
  const [shareLinkTooltipText, setShareLinkTooltipText] = useState("Share Link");

  // Get the current article URL
  const articletUrl = window.location.href;

  // Copy link to clipboard
  const copyLinkToClipboard = () => {
    logEvent(analytics, "copy_blog_article_link_to_clipboard", {
      article_title: title,
    });
    navigator.clipboard.writeText(articletUrl);
    setShareLinkTooltipText("Link Copied!");
    setTimeout(() => {
      setShareLinkTooltipText("Share Link");
    }, 3000);
  };

  // Share article on X
  const shareOnX = () => {
    logEvent(analytics, "share_blog_article_on_X", {
      article_title: title,
    });
    const link = `https://twitter.com/intent/tweet?via=glowlabsAI&text=${title}&url=${articletUrl}`;
    window.open(link, "_blank");
  };

  // Share article on LinkedIn
  const shareOnLinkedIn = () => {
    logEvent(analytics, "share_blog_article_on_LinkedIn", {
      article_title: title,
    });
    const link = `https://www.linkedin.com/shareArticle?mini=true&source=glowlabs.ai&title=${title}&url=${articletUrl}`;
    window.open(link, "_blank");
  };

  // Share article on Facebook
  const shareOnFacebook = () => {
    logEvent(analytics, "share_blog_article_on_Facebook", {
      article_title: title,
    });
    const link = `https://www.facebook.com/sharer/sharer.php?&t=${title}&u=${articletUrl}`;
    window.open(link, "_blank");
  };

  return (
    <Stack direction="row" spacing={2}>
      <Tooltip title={shareLinkTooltipText} placement="left">
        <IconButton sx={sharedStyle} onClick={copyLinkToClipboard}>
          <LinkIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on X" placement="left">
        <IconButton sx={sharedStyle} onClick={shareOnX}>
          <XIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on LinkedIn" placement="left">
        <IconButton sx={sharedStyle} onClick={shareOnLinkedIn}>
          <LinkedInIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on Facebook" placement="left">
        <IconButton sx={sharedStyle} onClick={shareOnFacebook}>
          <FacebookOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
