import { Box, Typography } from "@mui/material";
import theme from "styles/theme";

export function BetaIcon() {
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: 20,
        marginLeft: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="custom" sx={{ marginLeft: "10px", marginRight: "10px", fontSize: 12 }}>
        Beta
      </Typography>
    </Box>
  );
}
