import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useIsMobile } from "styles/breakpoint";
import theme from "styles/theme";
import { fetchOtherArticlesFromBuilder } from "../api/builder";
import { ArticlePreview } from "./ArticlePreview";

// Number of other articles to display
const NR_OTHER_ARTICLES_TO_DISPLAY = 2;

export function ArticleFooter({ handle }) {
  // Local state
  const [otherArticles, setOtherArticles] = useState([]);

  // Breakpoint
  const isMobile = useIsMobile();

  // Fetch other articles from builder
  useEffect(() => {
    const fetchArticles = async () => {
      const fetchedArticles = await fetchOtherArticlesFromBuilder(handle);
      setOtherArticles(fetchedArticles);
    };

    fetchArticles();
  }, [handle]);

  return (
    <Grid container spacing={isMobile ? 4 : 6} sx={{ alignItems: "flex-start", display: "flex" }}>
      {/* Divider */}
      <Grid mobile={12}>
        <Divider sx={{ borderWidth: 1, borderColor: theme.palette.grey.main }} />
      </Grid>

      {/* Header */}
      <Grid mobile={12}>
        <Typography variant="h1" component="div" sx={{ textAlign: "center" }}>
          Continue Reading
        </Typography>
      </Grid>

      {/* Article previews */}
      {otherArticles.length > 0 &&
        otherArticles.slice(0, NR_OTHER_ARTICLES_TO_DISPLAY).map((article, index) => (
          <Grid mobile={12} tablet={6} key={article.id}>
            <ArticlePreview data={article.data} currentHandle={handle} />
          </Grid>
        ))}
    </Grid>
  );
}
