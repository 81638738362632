import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Tooltip, Typography } from "@mui/material";
import CopyableChip from "components/copyableChip/CopyableChip";
import { calculateVideoAge, formatScore } from "features/outliers/utils/formatting";
import { useState } from "react";
import theme from "styles/theme";
import { StatsRow } from "./StatsRow";

export function VideoStats({ preview, latest }) {
  const [tooltipTitle, setTooltipTitle] = useState("Copy all");

  // Function to handle the copy to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // Change the tooltip to "Copied" when the text is copied
      setTooltipTitle("Copied");
    });
  };

  const handleMouseLeave = () => {
    // wait for 0.1 seconds before changing the tooltip back to "Copy"
    setTimeout(() => {
      setTooltipTitle("Copy all");
    }, 100);
  };

  return (
    <Box sx={{ m: 0, p: 0 }}>
      {/* Video Data from Preview */}
      <StatsRow label="glowAI Score" value={preview.med_view_score} formatter={null} />
      <StatsRow label="Published" value={preview.published_at} formatter={calculateVideoAge} suffix={" ago"} />
      <StatsRow label="Views" value={preview.view_count} formatter={formatScore} />

      {/* Video Data from Latest Details */}
      <StatsRow label="Likes" value={latest.like_count} formatter={formatScore} />
      <StatsRow label="Comments" value={latest.comment_count} formatter={formatScore} />
      {latest?.tags && (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", mb: 1 }}>
            <Typography variant="body4" sx={{ fontWeight: 300 }}>
              Video Tags
            </Typography>
            <Tooltip title={tooltipTitle} arrow>
              <ContentCopyIcon
                sx={{ cursor: "pointer", fontSize: "14px", color: theme.palette.grey.dark }}
                onClick={() => handleCopy(latest.tags)}
                onMouseLeave={handleMouseLeave}
              />
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", maxHeight: 150, overflowY: "auto" }}>
            {latest.tags.split(",").map((tag) => (
              <CopyableChip tag={tag} />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
