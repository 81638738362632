import { Box, Divider, Drawer, List, Stack, Typography } from "@mui/material";
import { useAppContext } from "components/appContext/AppContext";
import { SignInAvatar } from "components/signIn/SignInAvatar";
import { SignInButton } from "components/signIn/SignInButton";
import { auth } from "config/firebase";
import { useIsDesktop, useIsMobile, useIsTablet } from "styles/breakpoint";
import { APP_BAR_HEIGHT } from "styles/layout";
import theme from "styles/theme";

export const DRAWER_HEIGHT = 64;

export function ResponsiveDrawer({ userState, children }) {
  // App context
  const { mobileApplicationDrawerOpen, setMobileApplicationDrawerOpen } = useAppContext();

  // Breakpoint
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const isAuthenticated = userState.authenticated;

  var drawerWidth = "auto";
  if (isDesktop) {
    drawerWidth = 240;
  } else if (isTablet) {
    drawerWidth = 80;
  }
  return isMobile ? (
    <>
      <Drawer
        anchor="right"
        open={mobileApplicationDrawerOpen}
        onClose={() => setMobileApplicationDrawerOpen(false)}
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "60%",
            zIndex: theme.zIndex.drawerAboveAppBar,
          },
        }}
      >
        <Box display="flex" flexDirection="column" justifyContent="space-around">
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", height: APP_BAR_HEIGHT, display: "flex", alignItems: "center", px: 2 }}
          >
            Menu
          </Typography>
          <Divider sx={{ mb: 2, borderColor: theme.palette.grey.main }} />
          <Box sx={{ px: 2 }}>
            {isAuthenticated ? (
              <Stack direction="row" sx={{ display: "flex", alignItems: "center" }}>
                <SignInAvatar />
                <Stack direction="column" sx={{ ml: 2 }}>
                  <Typography variant="custom" sx={{ fontSize: 12 }}>
                    {auth.currentUser.displayName}
                  </Typography>
                  <Typography
                    variant="custom"
                    onClick={() => auth.signOut()}
                    sx={{ fontSize: 12, textDecoration: "underline", cursor: "pointer" }}
                  >
                    Sign out
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              <SignInButton alwaysLargeButton={true} openPopupInsteadOfLink={true} />
            )}
          </Box>
          <Divider sx={{ my: 2, borderColor: theme.palette.grey.main }} />
          {children}
        </Box>
      </Drawer>
    </>
  ) : (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          borderWidth: 0,
          zIndex: theme.zIndex.drawerBelowAppBar,
        },
      }}
    >
      <Box sx={{ height: APP_BAR_HEIGHT }} />
      <List sx={{ pl: 2, pr: 0, pt: 2 }}>{children}</List>
    </Drawer>
  );
}
