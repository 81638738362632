import { createContext, useContext, useState } from "react";
import {
  addVideoToFirestoreFavorites,
  getFavoritesVideoIdsFromFirestore,
  removeVideoFromFirestoreFavorites,
} from "../api/firestore";
import { DEFAULT_USER_FILTER_VALUES } from "../api/typesense/filters";

const OutliersContext = createContext();

export function OutliersProvider({ children }) {
  const MAX_FAVORITES = 500;

  // Local state Query / Filters
  const [query, setQuery] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(DEFAULT_USER_FILTER_VALUES);
  const [selectedFiltersUrl, setSelectedFiltersUrl] = useState("");

  // Local state Video Popup
  const [selectedVideo, setSelectedVideo] = useState({});
  const [fetchedVideoDetails, setFetchedVideoDetails] = useState({ latest: {}, history: {} });
  const [fetchedChannelDetails, setFetchedChannelDetails] = useState({ latest: {}, history: {} });
  const [openVideoPopup, setOpenVideoPopup] = useState(false);
  const [selectedVideoTab, setSelectedVideoTab] = useState(0);

  // Local state Video Popup Similar Thumbnails
  const [similarThumbnailInitialLoad, setSimilarThumbnailInitialLoad] = useState(true);

  // Favorite thumbnails of the user
  const [favorites, setFavorites] = useState([]);

  // Warning if max favorites has been reached
  const [showWarningPopup, setShowWarningPopup] = useState(false);

  // Video Popup Close Handler
  const closeVideoPopup = () => {
    setOpenVideoPopup(false);
    setSelectedVideo({});
    setFetchedVideoDetails({ latest: {}, history: {} });
    setFetchedChannelDetails({ latest: {}, history: {} });
    setSelectedVideoTab(0);
    setSimilarThumbnailInitialLoad(true);
  };

  // Add video Id to favorites and push to firestore
  const addToFavorites = async (data) => {
    if (favorites.length < MAX_FAVORITES) {
      const updatedFavorites = [data.id, ...favorites];
      setFavorites(updatedFavorites);

      // Then update firestore
      addVideoToFirestoreFavorites(data);
    } else {
      setShowWarningPopup(true);
    }
  };

  // Remove video ID from favorites and push to firestore
  const removeFromFavorites = (videoId) => {
    // First update local state to show the favorite update without delay to user
    const updatedFavorites = favorites.filter((id) => id !== videoId);
    setFavorites(updatedFavorites);

    // Then update firestore
    removeVideoFromFirestoreFavorites(videoId);
  };

  // Fetch favorites from Firestore and set local state
  const fetchFavoritesFromFirestore = async () => {
    const favoritesFromFirestore = await getFavoritesVideoIdsFromFirestore();
    setFavorites(favoritesFromFirestore);
  };

  return (
    <OutliersContext.Provider
      value={{
        MAX_FAVORITES,
        query,
        setQuery,
        selectedFilters,
        setSelectedFilters,
        selectedFiltersUrl,
        setSelectedFiltersUrl,
        selectedVideo,
        setSelectedVideo,
        fetchedVideoDetails,
        setFetchedVideoDetails,
        fetchedChannelDetails,
        setFetchedChannelDetails,
        openVideoPopup,
        setOpenVideoPopup,
        closeVideoPopup,
        selectedVideoTab,
        setSelectedVideoTab,
        similarThumbnailInitialLoad,
        setSimilarThumbnailInitialLoad,
        favorites,
        setFavorites,
        addToFavorites,
        removeFromFavorites,
        fetchFavoritesFromFirestore,
        showWarningPopup,
        setShowWarningPopup,
      }}
    >
      {children}
    </OutliersContext.Provider>
  );
}

export function useOutliersContext() {
  return useContext(OutliersContext);
}
