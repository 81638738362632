import { Box, Divider, MenuItem, Select, Skeleton, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { formatScore } from "features/outliers/utils/formatting";
import { useState } from "react";
import { useIsMobile, useIsTablet } from "styles/breakpoint";
import theme from "styles/theme";

// Helper to filter the dataset based on the selected time range
const filterDataByTimeRange = (data, range) => {
  const now = new Date();
  let filteredData = data;

  if (range === "last week") {
    const oneWeekAgo = new Date(now.setDate(now.getDate() - 7));
    filteredData = data.filter((item) => new Date(item.dt) >= oneWeekAgo);
  } else if (range === "last month") {
    const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
    filteredData = data.filter((item) => new Date(item.dt) >= oneMonthAgo);
  }

  return filteredData;
};

export function DetailsChart({ dataset, published_at = null }) {
  // Extract keys from the dataset
  const keys = Object.keys(dataset);

  // Breakpoints
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  // State
  const [selectedKey, setSelectedKey] = useState("Views");
  const [timeRange, setTimeRange] = useState("all time"); // Default to "all time"

  // Handle key change
  const handleKeyChange = (event) => {
    setSelectedKey(event.target.value);
  };

  // Handle time range change
  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  // Format data for the LineChart based on the selected key (add published_at 0-Value if it exists)
  let chartData = published_at
    ? [{ dt: new Date(published_at), value: 0 }].concat(
        dataset[selectedKey].map(({ dt, value }) => ({
          dt,
          value,
        })),
      )
    : dataset[selectedKey].map(({ dt, value }) => ({
        dt,
        value,
      }));

  // Filter the chart data based on the selected time range
  chartData = filterDataByTimeRange(chartData, timeRange);

  return (
    <Box
      sx={{
        position: "relative",
        flexGrow: 1,
        height: "100%",
        width: "100%",
        mt: 2,
      }}
    >
      {dataset ? (
        dataset[selectedKey].length > 1 ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              {/* Key selector */}
              <Select
                value={selectedKey}
                onChange={handleKeyChange}
                sx={{
                  typography: "body4",
                  borderRadius: 5,
                  height: "30px",
                  "& .MuiSelect-select": {
                    padding: "0 8px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.grey[400],
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                  },
                }}
                variant="outlined"
              >
                {keys.map((key) => (
                  <MenuItem
                    key={key}
                    value={key}
                    sx={{
                      typography: "body4",
                      minHeight: "30px",
                      padding: "4px 8px",
                    }}
                  >
                    {key}
                  </MenuItem>
                ))}
              </Select>

              {/* Time range selector */}
              <Select
                value={timeRange}
                onChange={handleTimeRangeChange}
                sx={{
                  typography: "body4",
                  borderRadius: 5,
                  height: "30px",
                  ml: 1,
                  "& .MuiSelect-select": {
                    padding: "0 8px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.grey[400],
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                  },
                }}
                variant="outlined"
              >
                <MenuItem
                  value="last week"
                  sx={{
                    typography: "body4",
                    minHeight: "30px",
                    padding: "4px 8px",
                  }}
                >
                  Last Week
                </MenuItem>
                <MenuItem
                  value="last month"
                  sx={{
                    typography: "body4",
                    minHeight: "30px",
                    padding: "4px 8px",
                  }}
                >
                  Last Month
                </MenuItem>
                <MenuItem
                  value="all time"
                  sx={{
                    typography: "body4",
                    minHeight: "30px",
                    padding: "4px 8px",
                  }}
                >
                  All Time
                </MenuItem>
              </Select>
            </Box>

            {!isMobile && <Divider flexItem sx={{ my: 1, px: 2 }} />}

            {/* Conditional rendering for the Line Chart */}
            {chartData.length > 0 ? (
              <LineChart
                slotProps={{
                  popper: {
                    sx: {
                      "& .MuiChartsTooltip-mark": {
                        display: "none",
                      },
                      "& .MuiChartsTooltip-root": {
                        "& .MuiTypography-root": {
                          typography: "body3",
                        },
                      },
                    },
                  },
                }}
                dataset={chartData}
                height={isMobile ? 300 : isTablet ? 400 : 580}
                margin={{ top: 15, right: 20, bottom: 30, left: 40 }}
                sx={{
                  "& .MuiChartsAxisHighlight-root": {
                    stroke: theme.palette.grey.main,
                  },
                }}
                xAxis={[
                  {
                    id: "Datetime",
                    scaleType: "time",
                    dataKey: "dt",
                    valueFormatter: (value) =>
                      isMobile
                        ? new Date(value).toLocaleDateString("en-US", {
                            year: "numeric",
                          })
                        : new Date(value).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }),
                    min: chartData[0]?.dt,
                  },
                ]}
                yAxis={[
                  {
                    scaleType: "linear",
                    //min: 0,
                    valueFormatter: (value) => formatScore(value),
                  },
                ]}
                series={[
                  {
                    id: "Views",
                    dataKey: "value",
                    //valueFormatter: (value) => formatScore(value),
                    showMark: false,
                    color: theme.palette.primary.main,
                  },
                ]}
                axisHighlight={{
                  x: "line",
                  y: "line",
                  stroke: theme.palette.grey[400],
                }}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  mt: 2,
                }}
              >
                <Typography variant="body3">No data available for the selected range.</Typography>
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              mt: 2,
            }}
          >
            <Typography variant="body3">Data not yet available!</Typography>
          </Box>
        )
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{
              width: 100,
              height: 40,
              borderRadius: 10,
              p: 0,
              my: 1,
            }}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: isMobile || isTablet ? 300 : 580, borderRadius: 5 }}
          />
        </Box>
      )}
    </Box>
  );
}
