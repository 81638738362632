import TuneIcon from "@mui/icons-material/Tune";
import { Button, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useIsDesktop } from "styles/breakpoint";
import theme from "styles/theme";

export function AppBarTuneButton({ buttonText, handleOpen }) {
  // Local state
  const [scrollAtTop, setScorllAtTop] = useState(true);

  // Breakpoint
  const isDesktop = useIsDesktop();

  // Scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScorllAtTop(false);
      } else {
        setScorllAtTop(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return isDesktop ? (
    <Button
      variant="outlined"
      endIcon={<TuneIcon sx={{ color: theme.palette.grey.dark }} />}
      onClick={handleOpen}
      sx={{
        height: { mobile: 10, tablet: 40 },
        px: 3,
        boxShadow: 0,
        textTransform: "none",
        bgcolor: theme.palette.white.main,
        borderRadius: 10,
        color: theme.palette.grey.dark,
        borderColor: theme.palette.grey.main,
        "&:hover": {
          borderColor: theme.palette.black.main,
          bgcolor: theme.palette.white.main,
          borderWidth: 1,
        },
      }}
    >
      <Typography variant="button">{buttonText}</Typography>
    </Button>
  ) : (
    <IconButton onClick={handleOpen}>
      <TuneIcon
        sx={{
          color: {
            mobile: scrollAtTop ? theme.palette.white.main : theme.palette.grey.dark,
            tablet: theme.palette.grey.dark,
          },
        }}
      />
    </IconButton>
  );
}
