import { BuilderComponent } from "@builder.io/react";
import { Typography } from "@mui/material";
import { LandingPageStandard } from "components/page";
import { CustomHelmet } from "features/helmet";

export default function PrivacyPolicyPage() {
  return (
    <>
      <CustomHelmet index={false} follow={false} />
      <LandingPageStandard>
        <Typography variant="h1">Privacy statement</Typography>
        <BuilderComponent model="privacy-policy" />
      </LandingPageStandard>
    </>
  );
}
