import { Box, Modal } from "@mui/material";
import theme from "styles/theme";

export function Popup({ open, handleClose, children }) {
  return (
    <Modal open={open} onClose={handleClose} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box
        sx={{
          position: "relative",
          backgroundColor: theme.palette.white.main,
          borderRadius: "20px",
          boxShadow: 5,
          maxWidth: "420px",
          justifyContent: "left",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          padding: "20px",
          margin: 2,
        }}
      >
        {/* Children */}
        {children}
      </Box>
    </Modal>
  );
}
