import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SavedSearchOutlinedIcon from "@mui/icons-material/SavedSearchOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useAppContext } from "components/appContext/AppContext";
import { DrawerListItemL1 } from "./DrawerListItemL1";
import { DrawerListItemL2 } from "./DrawerListItemL2";
import { ResponsiveDrawer } from "./ResponsiveDrawer";

export function ApplicationDrawer({ userState }) {
  // App context
  const { lastOutliersSearchParameters } = useAppContext();

  // Auth
  const isDeveloper = userState.developer;

  return (
    <ResponsiveDrawer userState={userState}>
      <DrawerListItemL1 longPageName="Outlier Database" shortPageName="Outliers" first={true} />
      <DrawerListItemL2
        longPageName="Search"
        shortPageName="Search"
        linkToPagePathname={"/app/outliers/search"}
        linkToPageParameters={lastOutliersSearchParameters}
        filledIcon={<SavedSearchOutlinedIcon />}
        outlienedIcon={<SearchIcon />}
        userState={userState}
        authCheck={false}
      />
      <DrawerListItemL2
        longPageName="Favorites"
        shortPageName="Favorites"
        linkToPagePathname="/app/outliers/favorites"
        filledIcon={<FavoriteIcon />}
        outlienedIcon={<FavoriteBorderIcon />}
        userState={userState}
        authCheck={true}
      />
      {isDeveloper && (
        <>
          <DrawerListItemL1 longPageName="Thumbnail Ideation" shortPageName={"Ideation"} />
          <DrawerListItemL2
            longPageName="Sessions"
            shortPageName="Sessions"
            linkToPagePathname="/app/ideation"
            filledIcon={<AutoAwesomeIcon />}
            outlienedIcon={<AutoAwesomeOutlinedIcon />}
          />
        </>
      )}
    </ResponsiveDrawer>
  );
}
