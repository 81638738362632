import { AppPageStandard, InnerContent, OuterBox } from "components/page";
import { ApplicationAppBar } from "features/appBar";
import { CustomHelmet } from "features/helmet";
import {
  OutliersProvider,
  REFRESH_MS,
  SearchInput,
  SearchResultsGrid,
  searchFunction,
  setupSearch,
} from "features/outliers";
import { VideoPopup } from "features/outliers/components/VideoPopup/VideoPopup";
import { useEffect, useState } from "react";
import { InstantSearch } from "react-instantsearch";
import theme from "styles/theme";

const TITLE = "Search Outliers";
const TITLE_COLOR = theme.palette.white.main;
const GRADIENT_COLOR_FROM = theme.palette.ci.semiDark;
const GRADIENT_COLOR_TO = theme.palette.ci.light;
const ONLY_SHOW_PAGE_HEADER_ON_MOBILE = true;

export default function OutliersPage({ userState }) {
  // Local state
  const [typesenseInstance, setTypesenseInstance] = useState({});
  const [retrievingKeys, setRetrievingKeys] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [indexName, setIndexName] = useState(process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION);
  const [manualIndexInput, setManualIndexInput] = useState(false);

  useEffect(() => {
    // When loading the page for the first time, set up the search client
    if (initialLoad) {
      setupSearch(retrievingKeys, setRetrievingKeys, setTypesenseInstance, setInitialLoad);
    }

    // Refresh the search client every REFRESH_MS milliseconds to avoid the expiry of the typesense API key
    const interval = setInterval(() => {
      if (!manualIndexInput) setupSearch(retrievingKeys, setRetrievingKeys, setTypesenseInstance, setInitialLoad);
    }, REFRESH_MS);

    return () => clearInterval(interval);
  }, [retrievingKeys, initialLoad]);

  return (
    <>
      <CustomHelmet title={"glowAI Outlier DB"} index={true} follow={false} />
      {initialLoad ? (
        // While the search client is being set up, show the app bar and drawer
        <AppPageStandard
          userState={userState}
          title={TITLE}
          titleColor={TITLE_COLOR}
          gradientColorFrom={GRADIENT_COLOR_FROM}
          gradientColorTo={GRADIENT_COLOR_TO}
          onlyShowPageHeaderOnMobile={ONLY_SHOW_PAGE_HEADER_ON_MOBILE}
        />
      ) : (
        // Once the search client is set up, also show the search input and the search results grid
        <OutliersProvider>
          <OuterBox>
            <InstantSearch
              indexName={indexName ? indexName : process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION}
              searchClient={typesenseInstance.searchClient}
              searchFunction={(helper) => searchFunction(helper)}
              future={{ preserveSharedStateOnUnmount: true }}
            >
              <ApplicationAppBar userState={userState} showLogo={false}>
                <SearchInput />
              </ApplicationAppBar>
              <InnerContent
                userState={userState}
                title={TITLE}
                titleColor={TITLE_COLOR}
                gradientColorFrom={GRADIENT_COLOR_FROM}
                gradientColorTo={GRADIENT_COLOR_TO}
                onlyShowPageHeaderOnMobile={ONLY_SHOW_PAGE_HEADER_ON_MOBILE}
              >
                <SearchResultsGrid
                  userState={userState}
                  indexName={indexName ? indexName : process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION}
                />
              </InnerContent>
            </InstantSearch>
          </OuterBox>
          <VideoPopup userState={userState} />
        </OutliersProvider>
      )}
    </>
  );
}
