import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import theme from "styles/theme";

export function TagsChange({ dt, oldTags, newTags, isLatest }) {
  const [tooltipTitle, setTooltipTitle] = useState("Copy");

  // Function to handle the copy to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // Change the tooltip to "Copied" when the text is copied
      setTooltipTitle("Copied");
    });
  };

  const handleMouseLeave = () => {
    // wait for 0.1 seconds before changing the tooltip back to "Copy"
    setTimeout(() => {
      setTooltipTitle("Copy");
    }, 100);
  };

  // Convert comma-separated strings into arrays of tags
  const oldTagsArray = oldTags ? oldTags.split(",").map((tag) => tag.trim()) : [];
  const newTagsArray = newTags ? newTags.split(",").map((tag) => tag.trim()) : [];

  // Find added and removed tags
  const addedTags = newTagsArray.filter((tag) => !oldTagsArray.includes(tag));
  const removedTags = oldTagsArray.filter((tag) => !newTagsArray.includes(tag));
  const unchangedTags = newTagsArray.filter((tag) => oldTagsArray.includes(tag));

  // Base styles for chips
  const chipBaseSx = {
    mr: 0.2,
    my: 0.1,
    borderColor: "divider",
    border: "0.5px solid",
  };

  // Function to get specific styles based on tag type
  const getTagStyles = (type) => {
    switch (type) {
      case "added":
        return { ...chipBaseSx, backgroundColor: "lightgreen" };
      case "removed":
        return { ...chipBaseSx, backgroundColor: "lightcoral", textDecoration: "line-through" };
      case "unchanged":
        return { ...chipBaseSx, backgroundColor: theme.palette.white.main };
      default:
        return chipBaseSx;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
      {/* Show the date and if this is the latest change */}
      <Typography variant="body4" sx={{ fontWeight: "bold" }}>
        {"" +
          (isLatest ? "Latest Tags (" : "Tags Change (") +
          new Date(dt).toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }) +
          ")"}
      </Typography>

      {/* Display all tags in one row */}
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {/* Display added tags */}
        {addedTags.map((tag, index) => (
          <Tooltip title={tooltipTitle} arrow>
            <Chip
              key={`added-${index}`}
              label={
                <Typography variant="body4" fontWeight={300}>
                  {tag}
                </Typography>
              }
              size="small"
              sx={getTagStyles("added")}
              onClick={() => handleCopy(tag)}
              onMouseLeave={handleMouseLeave}
            />
          </Tooltip>
        ))}

        {/* Display removed tags */}
        {removedTags.map((tag, index) => (
          <Tooltip title={tooltipTitle} arrow>
            <Chip
              key={`removed-${index}`}
              label={
                <Typography variant="body4" fontWeight={300}>
                  {tag}
                </Typography>
              }
              size="small"
              sx={getTagStyles("removed")}
              onClick={() => handleCopy(tag)}
              onMouseLeave={handleMouseLeave}
            />
          </Tooltip>
        ))}

        {/* Display unchanged tags */}
        {unchangedTags.map((tag, index) => (
          <Tooltip title={tooltipTitle} arrow>
            <Chip
              key={`unchanged-${index}`}
              label={
                <Typography variant="body4" fontWeight={300}>
                  {tag}
                </Typography>
              }
              size="small"
              sx={getTagStyles("unchanged")}
              onClick={() => handleCopy(tag)}
              onMouseLeave={handleMouseLeave}
            />
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
}
