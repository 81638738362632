import { FavoritesGrid } from "./FavoritesGrid";
import { useOutliersContext } from "./OutliersContext";
import { VideoPopup } from "./VideoPopup/VideoPopup";

export function FavoritesGridWithVideoPopup({ userState }) {
  // context
  const { openVideoPopup } = useOutliersContext();

  return (
    <>
      <FavoritesGrid userState={userState} reload={openVideoPopup} />
      <VideoPopup userState={userState} />
    </>
  );
}
