import { Box } from "@mui/system";
import { WhiteLandingAppBar } from "features/appBar";
import { Footer } from "features/footer";
import { MAX_SECTION_WIDTH } from "styles/layout";

export function LandingPageStandard({ children }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <WhiteLandingAppBar />
      <Box
        sx={{
          width: "100%",
          maxWidth: MAX_SECTION_WIDTH,
          px: 2,
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
}
