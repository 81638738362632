import { Box, Stack } from "@mui/material";
import { ApplicationDrawer } from "features/drawer";
import { FeedbackSpeedDial } from "features/feedback";
import { useIsMobile } from "styles/breakpoint";
import { APP_BAR_HEIGHT } from "styles/layout";
import { PageHeader } from "./PageHeader";

export function InnerContent({
  children,
  userState,
  title,
  titleColor,
  gradientColorFrom,
  gradientColorTo,
  onlyShowPageHeaderOnMobile = false,
}) {
  const isMobile = useIsMobile();
  return (
    <>
      <ApplicationDrawer userState={userState} />
      <FeedbackSpeedDial />
      <Stack direction="column" component="main" sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            p: { mobile: 0, tablet: 0 },
            px: { tablet: 2 },
            mt: { mobile: 0, tablet: APP_BAR_HEIGHT },
          }}
        >
          {(isMobile || !onlyShowPageHeaderOnMobile) && (
            <PageHeader
              title={title}
              titleColor={titleColor}
              gradientColorFrom={gradientColorFrom}
              gradientColorTo={gradientColorTo}
            />
          )}
        </Box>
        <Box sx={{ width: "100%", p: 2 }}>{children}</Box>
      </Stack>
    </>
  );
}
