import { BuilderComponent } from "@builder.io/react";
import { Typography } from "@mui/material";
import { LandingPageStandard } from "components/page";
import { CustomHelmet } from "features/helmet";

export default function TermsOfServicePage() {
  return (
    <>
      <CustomHelmet index={false} follow={false} />
      <LandingPageStandard>
        <Typography variant="h1">Terms of service</Typography>
        <BuilderComponent model="terms-of-service" />
      </LandingPageStandard>
    </>
  );
}
