import { analytics, logEvent } from "config/firebase";

// Redirect to YouTube video when clicking on the thumbnail
export function redirectToYouTubeVideo(data) {
  logEvent(analytics, "redirect_to_youtube_video", {
    videoId: data.id,
    videoTitle: data.title,
    channelTitle: data.channel_title,
  });
  window.open("https://www.youtube.com/watch?v=" + data.id, "_blank");
}

// Redirect to YouTube channel when clicking on the channel name
export function redirectToYouTubeChannel(data) {
  if (data.custom_url) {
    logEvent(analytics, "redirect_to_youtube_channel", {
      channelTitle: data.channel_title,
      customUrl: data.custom_url,
    });
    window.open("https://www.youtube.com/" + data.custom_url, "_blank");
  } else return;
}
