import { Box, Modal } from "@mui/material";
import { useAppContext } from "components/appContext/AppContext";
import { SignInBox } from "features/authentication";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DrawerListItemL2Content } from "./DrawerListItemL2Content";

export function DrawerListItemL2({
  longPageName,
  shortPageName,
  linkToPagePathname,
  linkToPageParameters = "",
  filledIcon,
  outlienedIcon,
  userState,
  authCheck,
}) {
  // App context
  const { setMobileApplicationDrawerOpen } = useAppContext();

  // Local state
  const [openAuthPopup, setOpenAuthPopup] = useState(false);

  // Check if user is authenticated
  const isAuthenticated = userState?.authenticated || false;

  // Url
  const location = useLocation();
  const navigate = useNavigate();

  // Nothing should happen when you click on the page you are currently at
  const disabled = location.pathname.startsWith(linkToPagePathname);

  const handleNotAuthenticatedClick = () => {
    setOpenAuthPopup(true);
  };

  const handleClick = () => {
    navigate(linkToPagePathname + linkToPageParameters);
    setMobileApplicationDrawerOpen(false);
  };

  const handleClose = () => {
    setOpenAuthPopup(false);
  };

  return (
    <>
      {authCheck && !isAuthenticated ? (
        <div
          onClick={handleNotAuthenticatedClick}
          style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
        >
          <DrawerListItemL2Content
            longPageName={longPageName}
            shortPageName={shortPageName}
            linkToPagePathname={linkToPagePathname}
            filledIcon={filledIcon}
            outlienedIcon={outlienedIcon}
          />
        </div>
      ) : disabled ? (
        <DrawerListItemL2Content
          longPageName={longPageName}
          shortPageName={shortPageName}
          linkToPagePathname={linkToPagePathname}
          filledIcon={filledIcon}
          outlienedIcon={outlienedIcon}
        />
      ) : (
        // <Link to={linkToPagePathname + linkToPageParameters} style={{ textDecoration: "none", color: "inherit" }}>
        <Box onClick={() => handleClick()} sx={{ cursor: "pointer" }}>
          <DrawerListItemL2Content
            longPageName={longPageName}
            shortPageName={shortPageName}
            linkToPagePathname={linkToPagePathname}
            filledIcon={filledIcon}
            outlienedIcon={outlienedIcon}
          />
        </Box>
        // </Link>
      )}
      <Modal
        open={openAuthPopup}
        onClose={handleClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <>
          <SignInBox
            headerText="Sign in to view your saved favorites - it's free!"
            linkToAfterSignIn="/app/outliers/favorites"
          />
        </>
      </Modal>
    </>
  );
}
