import { createContext, useContext, useState } from "react";

const AppContext = createContext();

export function AppProvider({ children }) {
  // Local state
  const [lastOutliersSearchParameters, setLastOutliersSearchParameters] = useState("");
  const [mobileApplicationDrawerOpen, setMobileApplicationDrawerOpen] = useState(false);

  return (
    <AppContext.Provider
      value={{
        lastOutliersSearchParameters,
        setLastOutliersSearchParameters,
        mobileApplicationDrawerOpen,
        setMobileApplicationDrawerOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
