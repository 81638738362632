import { LandingPageStandard } from "components/page";
import { Article, fetchArticleFromBuilder } from "features/blog";
import { CustomHelmet } from "features/helmet";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function BlogArticlePage() {
  // Local state
  const [article, setArticle] = useState(null);

  // Router
  const { handle } = useParams();
  const navigate = useNavigate();

  // When the component loads, fetch article from builder
  useEffect(() => {
    const fetchArticle = async () => {
      const fetchedArticle = await fetchArticleFromBuilder(handle);
      if (!fetchedArticle) {
        navigate("/blog");
      } else {
        setArticle(fetchedArticle);
      }
    };

    fetchArticle();
  }, [handle, navigate]);

  return (
    <>
      <CustomHelmet title={article?.data?.title} description={article?.data?.blurb} image={article?.data?.image} />
      <LandingPageStandard>
        <Article article={article} />
      </LandingPageStandard>
    </>
  );
}
