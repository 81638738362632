export const thumbnailStyles = {
  sx: { maxWidth: "100%", borderRadius: "10px" },
};

export const titleBelowThumbnailStyles = {
  sx: {
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    flex: "1 1 auto",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};
