import { AppBar, Box, Toolbar, useMediaQuery } from "@mui/material";
import { SignInButton } from "components/signIn/SignInButton";
import { useState } from "react";
import { useIsDesktop, useIsTablet } from "styles/breakpoint";
import { APP_BAR_HEIGHT, MAX_SECTION_WIDTH } from "styles/layout";
import theme from "styles/theme";
import { BetaIcon } from "../BetaIcon";
import { Logo } from "../Logo";
import { LandingMenu } from "./LandingMenu";
import { LinkSection } from "./LinkSection";
import { LANDING_LINKS } from "./landingLinks";

export function TransparentLandingAppBar() {
  // Local state
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Breakpoint
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isVerySmallMobile = useMediaQuery("(max-width:350px)");

  // Event handlers
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        justifyContent: "center",
        display: "flex",
        bgcolor: "transparent",
      }}
    >
      <AppBar
        position={"static"}
        sx={{
          zIndex: theme.zIndex.appBar,
          bgcolor: "transparent",
          boxShadow: "none",
          width: "100%",
          maxWidth: MAX_SECTION_WIDTH,
          margin: "auto",
          left: 0,
          right: 0,
          px: 2,
        }}
      >
        <Toolbar
          sx={{ alignItems: "center", justifyContent: "space-between", width: "100%", p: 0, height: APP_BAR_HEIGHT }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            {/* Display Logo */}
            <Logo color="white" />
            {(isTablet || isDesktop) && <BetaIcon />}

            {/* Display Links */}
            {isDesktop && (
              <Box sx={{ marginLeft: "40px" }}>
                {LANDING_LINKS.map((link, index) => (
                  <LinkSection
                    key={index}
                    text={link.text}
                    textColor={theme.palette.white.main}
                    destination={link.destination}
                  />
                ))}
              </Box>
            )}
          </Box>

          {/* Display Sign In */}
          {isDesktop ? (
            <SignInButton
              backgroundColor={theme.palette.white.main}
              backgroundColorHover="rgba(255, 255, 255, 0.8)"
              fontColor="rgba(90, 185, 245, 1)"
            />
          ) : (
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              {!isVerySmallMobile && (
                <Box sx={{ mr: 2 }}>
                  <SignInButton
                    backgroundColor={theme.palette.white.main}
                    backgroundColorHover="rgba(255, 255, 255, 0.8)"
                    fontColor="rgba(90, 185, 245, 1)"
                    alwaysLargeButton={true}
                  />
                </Box>
              )}
              <LandingMenu
                drawerOpen={drawerOpen}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                menuIconColor={theme.palette.white.main}
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
