import { RANDOM_SEARCH_FILTER_STRING, RANDOM_SEARCH_SORT_BY_STRING } from "./filters";
import { triggerRegularSearchRaw } from "./regularSearch";

// Trigger a random search in Typesense
export async function triggerRandomSearch(setIndexUiState) {
  const randomQuery = createRandomQuery();

  // const channelBlacklist = await getChannelBlacklist();
  // const excluded = channelBlacklist ? { channel_title: channelBlacklist } : null;

  // Construct filter string from random search filter and excluded values
  const filterStingParts = [];
  filterStingParts.push(RANDOM_SEARCH_FILTER_STRING);
  // filterStingParts.push(convertExcludedValuesToFilterString(excluded));
  const filterString = filterStingParts.join(" && ");

  triggerRegularSearchRaw(setIndexUiState, randomQuery, filterString, RANDOM_SEARCH_SORT_BY_STRING);
}

// Create a random query consisting of a single letter
function createRandomQuery() {
  // Define the characters that can be used in the random query
  const chars = "abcdfghijklmnopqrstuwz";
  return chars[Math.floor(Math.random() * chars.length)];
}

// Convert excluded values to a filter string that can be used in Typesense
function convertExcludedValuesToFilterString(excludedValues) {
  const filterParts = [];

  // Add excluded values to filter string
  if (excludedValues) {
    for (const [category, value] of Object.entries(excludedValues)) {
      filterParts.push(`${category}:!=[${value}]`);
    }
  }
  return filterParts.join(" && ");
}
