import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import theme from "styles/theme";
import { Popup } from "./Popup";

export function PopupWithTitleAndClose({ open, handleClose, title, children }) {
  return (
    <Popup open={open} handleClose={handleClose}>
      {/* Close icon in top right corner */}
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.black.main,
        }}
      >
        <CloseIcon />
      </IconButton>

      {/* Title  */}
      <Typography component="h1" sx={{ fontSize: 20, mb: 2 }}>
        {title}
      </Typography>

      {/* Children */}
      {children}
    </Popup>
  );
}
