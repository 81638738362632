export const accordionStyles = {
  sx: {
    width: "100%",
    boxShadow: "none",
    m: 0,
    "&:before": {
      display: "none",
    },
    borderRadius: 10,
    "&.Mui-expanded": { m: 0, p: 0 },
  },
};

export const accordionSummaryStyles = {
  sx: {
    width: "100%",
    m: 0,
    p: 0,
    typography: "body3",
    fontWeight: 500,
  },
};
