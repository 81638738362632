import LabelIcon from "@mui/icons-material/Label";
import TitleIcon from "@mui/icons-material/Title";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { Box, Divider, MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";
import { useIsMobile } from "styles/breakpoint";
import theme from "styles/theme";
import { TagsChange } from "./TagsChange";
import { TitleChange } from "./TitleChange";

// Icons for different metadata fields
const fieldIcons = {
  title: <TitleIcon />,
  tags: <LabelIcon />,
};

// Pulsating dot style for the latest change
const pulsatingDotStyle = {
  animation: "pulse 1.5s infinite",
  "@keyframes pulse": {
    "0%": { transform: "scale(1.0)" },
    "50%": { transform: "scale(1.1)" },
    "100%": { transform: "scale(1.0)" },
  },
};

export function ChangesTab({ changes }) {
  const isMobile = useIsMobile();

  // State for select boxes
  const [selectedField, setSelectedField] = useState("all");
  const [selectedTimeRange, setSelectedTimeRange] = useState("all time");

  // Flatten changes and sort by date
  const timelineData = [];
  if (!changes) return null;
  Object.keys(changes).forEach((field) => {
    Object.keys(changes[field]).forEach((dt) => {
      timelineData.push({ field, dt: new Date(dt), value: changes[field][dt] });
    });
  });

  // Sort changes by date (oldest first)
  timelineData.sort((a, b) => a.dt - b.dt);

  // Filter data by selected field
  const filteredData = timelineData.filter((item) => {
    const fieldMatches = selectedField === "all" || item.field === selectedField;

    // Filter by time range
    const now = new Date();
    let timeMatches = true;

    if (selectedTimeRange === "last week") {
      const oneWeekAgo = new Date(now.setDate(now.getDate() - 7));
      timeMatches = item.dt >= oneWeekAgo;
    } else if (selectedTimeRange === "last month") {
      const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
      timeMatches = item.dt >= oneMonthAgo;
    }

    return fieldMatches && timeMatches;
  });

  // Find the latest changes for title and tags
  const latestChanges = {
    title: filteredData.filter((item) => item.field === "title").slice(-1)[0],
    tags: filteredData.filter((item) => item.field === "tags").slice(-1)[0],
  };

  // Handle field change
  const handleFieldChange = (event) => {
    setSelectedField(event.target.value);
  };

  // Handle time range change
  const handleTimeRangeChange = (event) => {
    setSelectedTimeRange(event.target.value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", px: 2, my: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        {/* Field selector */}
        <Select
          value={selectedField}
          onChange={handleFieldChange}
          sx={{
            typography: "body4",
            borderRadius: 5,
            height: "30px",
            "& .MuiSelect-select": {
              padding: "0 8px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.grey[400],
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
          }}
          variant="outlined"
        >
          <MenuItem value="all" sx={{ typography: "body4", minHeight: "30px", padding: "4px 8px" }}>
            All Changes
          </MenuItem>
          <MenuItem value="title" sx={{ typography: "body4", minHeight: "30px", padding: "4px 8px" }}>
            Title Changes
          </MenuItem>
          <MenuItem value="tags" sx={{ typography: "body4", minHeight: "30px", padding: "4px 8px" }}>
            Tag Changes
          </MenuItem>
        </Select>

        {/* Time range selector */}
        <Select
          value={selectedTimeRange}
          onChange={handleTimeRangeChange}
          sx={{
            typography: "body4",
            borderRadius: 5,
            height: "30px",
            ml: 1,
            "& .MuiSelect-select": {
              padding: "0 8px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.grey[400],
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
          }}
          variant="outlined"
        >
          <MenuItem value="last week" sx={{ typography: "body4", minHeight: "30px", padding: "4px 8px" }}>
            Last Week
          </MenuItem>
          <MenuItem value="last month" sx={{ typography: "body4", minHeight: "30px", padding: "4px 8px" }}>
            Last Month
          </MenuItem>
          <MenuItem value="all time" sx={{ typography: "body4", minHeight: "30px", padding: "4px 8px" }}>
            All Time
          </MenuItem>
        </Select>
      </Box>

      {!isMobile && <Divider flexItem sx={{ m: 0, px: 2 }} />}

      {filteredData.length > 0 ? (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              p: 0,
              m: 0,
            },
            m: 0,
            p: 0,
          }}
        >
          {filteredData.map((item, index) => {
            // Check if the value is valid for rendering
            const canRender = item.value;

            return canRender ? (
              <TimelineItem key={index} sx={{ display: "flex", alignItems: "center" }}>
                {/* Icon and connector */}
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      m: 0,
                      ...(item === latestChanges[item.field] && pulsatingDotStyle),
                    }}
                  >
                    {fieldIcons[item.field]}
                  </TimelineDot>
                  {index !== filteredData.length - 1 && <TimelineConnector />}
                </TimelineSeparator>

                {/* Value on the right side (TitleChange or TagsChange) */}
                <TimelineContent
                  sx={{
                    my: 0,
                    p: 0,
                    ml: 2,
                    textAlign: "left",
                  }}
                >
                  {item.field === "tags" ? (
                    <TagsChange
                      dt={item.dt}
                      oldTags={filteredData[index - 1]?.field === "tags" ? filteredData[index - 1].value : ""}
                      newTags={item.value}
                      isLatest={item === latestChanges[item.field]}
                    />
                  ) : (
                    <TitleChange
                      dt={item.dt}
                      oldTitle={filteredData[index - 1]?.field === "title" ? filteredData[index - 1].value : ""}
                      newTitle={item.value}
                      isLatest={item === latestChanges[item.field]}
                    />
                  )}
                </TimelineContent>
              </TimelineItem>
            ) : null;
          })}
        </Timeline>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            mt: 4,
          }}
        >
          <Typography variant="body3">No changes found</Typography>
        </Box>
      )}
    </Box>
  );
}
