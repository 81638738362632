import { Box, CircularProgress, Fade, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppContext } from "components/appContext/AppContext";
import { PopupWithTitleAndClose } from "components/popups/PopupWithTitleAndClose";
import { analytics, logEvent } from "config/firebase";
import { useEffect, useRef, useState } from "react";
import { useConfigure, useInfiniteHits, useInstantSearch, useSortBy } from "react-instantsearch";
import { useIsMobile } from "styles/breakpoint";
import theme from "styles/theme";
import { HITS_PER_PAGE } from "../api/typesense/config";
import { filterAndSortCombinedHits } from "../api/typesense/filters";
import { LIVE_SEARCH_THRESHOLD, triggerLiveSearch } from "../api/typesense/liveSearch";
import { triggerSimilarSearch } from "../api/typesense/similarSearch";
import { triggerUrlBasedSearch } from "../api/typesense/urlBasedSearch";
import { FancyLinearProgress } from "./FancyLinearProgress";
import { LoadingVideoPreviewPlaceholder } from "./LoadingVideoPreviewPlaceholder";
import { useOutliersContext } from "./OutliersContext";
import { VideoPreview } from "./VideoPreview";

export function SearchResultsGrid({ props, userState, indexName, elementsPerRow = null, similarSearchForId = null }) {
  // App context
  const { setLastOutliersSearchParameters } = useAppContext();

  // Instantsearch state
  const { indexUiState, setIndexUiState, status } = useInstantSearch();

  // Instantsearch search results
  const { hits, isLastPage, showMore, results } = useInfiniteHits(props);

  // Required to avoid the display of no search results message during the initial load
  const [initialLoad, setInitialLoad] = useState(true);

  // The combined hits of Typesense Search Results and Live Search Results (not sorted and filtered)
  const [rawCombinedHits, setRawCombinedHits] = useState(hits);

  // The hits that should be displayed to the user
  const [hitsToDisplay, setHitsToDisplay] = useState(hits);

  // Cache of the previous search
  const [liveSearchState, setLiveSearchState] = useState({ query: "", combinedHits: [] });

  // Flag to indicate if the search is live
  const [isLiveSearch, setIsLiveSearch] = useState(false);

  // Progress of the live search
  const [liveSearchProgress, setIsLiveSearchProgress] = useState({ fetched: 0, total: 100 });

  // Context state
  const {
    MAX_FAVORITES,
    selectedFilters,
    setQuery,
    setSelectedFilters,
    setSelectedFiltersUrl,
    favorites,
    setFavorites,
    addToFavorites,
    removeFromFavorites,
    showWarningPopup,
    setShowWarningPopup,
    fetchFavoritesFromFirestore,
  } = useOutliersContext();

  // Ref to the bottom of the search results page
  const bottomSearchResultsRef = useRef(null);

  // Breakpoint
  const isMobile = useIsMobile();

  // Configure the search
  useConfigure({
    hitsPerPage: HITS_PER_PAGE,
    index: indexName ? indexName : process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION,
  });
  useSortBy({
    items: [{ value: indexName ? indexName : process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION, label: "Index" }],
  });

  function removeHitById(hitIdToRemove) {
    setHitsToDisplay((currentHits) => currentHits.filter((hit) => hit.id !== hitIdToRemove));
  }

  function updateLiveSearchProgress(totalTime) {
    const totalSteps = 20;
    const stepTime = totalTime / totalSteps;
    let currentStep = 0;

    const intervalId = setInterval(() => {
      currentStep++;
      const progress = { fetched: currentStep, total: 100 };
      setIsLiveSearchProgress(progress);

      if (currentStep >= totalSteps) {
        clearInterval(intervalId);
      }
    }, stepTime);
  }

  // USE EFFECT HOOKS START

  // Fetch favorites from Firestore when the user is authenticated
  useEffect(() => {
    if (userState.authenticated) {
      fetchFavoritesFromFirestore();
    } else {
      setFavorites([]);
    }
  }, [userState.authenticated]);

  // Fetch search results when loading for the first time
  useEffect(() => {
    if (similarSearchForId) {
      triggerSimilarSearch(similarSearchForId, setIndexUiState);
    } else {
      triggerUrlBasedSearch(
        setQuery,
        setSelectedFilters,
        setSelectedFiltersUrl,
        setIndexUiState,
        indexName,
        HITS_PER_PAGE,
      );
      setLastOutliersSearchParameters(window.location.search);
    }
    setInitialLoad(false);
  }, []);

  // Add an event listener to make sure that the search is triggered when navigating the url history
  useEffect(() => {
    const functionToExecuteWhenNavigatingUrlHistory = () => {
      if (similarSearchForId) {
        triggerSimilarSearch(similarSearchForId, setIndexUiState);
      } else {
        triggerUrlBasedSearch(
          setQuery,
          setSelectedFilters,
          setSelectedFiltersUrl,
          setIndexUiState,
          indexName,
          HITS_PER_PAGE,
        );
      }
    };
    window.addEventListener("popstate", functionToExecuteWhenNavigatingUrlHistory);
    return () => window.removeEventListener("popstate", functionToExecuteWhenNavigatingUrlHistory);
  }, []);

  // When the user scrolls to the bottom of the page, fetch more search results
  useEffect(() => {
    if (bottomSearchResultsRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(bottomSearchResultsRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  // Once the hits update check if a live search needs to be triggered and update the hits to display accordingly
  useEffect(() => {
    // Immediately show the hits found in typesense
    setHitsToDisplay(hits);

    // Check if a live search needs to performed
    if (
      results.nbHits < LIVE_SEARCH_THRESHOLD &&
      indexUiState.query &&
      indexUiState.query.length > 0 &&
      indexUiState.query !== liveSearchState.query &&
      status === "idle"
    ) {
      // Log event
      logEvent(analytics, "live_search_triggered", {
        query: indexUiState.query,
        filters: indexUiState.configure.filters,
      });

      // Update the live search progress bar
      updateLiveSearchProgress(2000);

      // Tigger the live search
      triggerLiveSearch(
        hits,
        indexUiState.query,
        selectedFilters,
        setRawCombinedHits,
        setLiveSearchState,
        setIsLiveSearchProgress,
        setIsLiveSearch,
      );
    } else {
      // If no live search needs to be performed but the query has changed reset the live search loading bar
      if (indexUiState.query !== liveSearchState.query) {
        setIsLiveSearchProgress({ fetched: 0, total: 100 });
        setIsLiveSearch(false);
      }
    }
  }, [hits, results]);

  // When new raw combined hits are set by the live search, apply the latest filters. This needs to happen in a
  // useEffect hook as the fitlers can change while the live search is running
  useEffect(() => {
    if (results.nbHits < LIVE_SEARCH_THRESHOLD) {
      setHitsToDisplay(filterAndSortCombinedHits(rawCombinedHits, selectedFilters));
    }
  }, [rawCombinedHits, selectedFilters]);

  //Check if the live search is finished
  useEffect(() => {
    if (isLiveSearch && liveSearchProgress.total > 1 && liveSearchProgress.fetched >= liveSearchProgress.total) {
      setIsLiveSearch(false);
    }
  }, [liveSearchProgress]);

  // Set a timeout to stop the live search after 60 seconds
  useEffect(() => {
    let timeoutId;
    if (isLiveSearch) {
      timeoutId = setTimeout(() => {
        setIsLiveSearch(false);
      }, 60000);
    }
    return () => clearTimeout(timeoutId);
  }, [isLiveSearch]);

  // USE EFFECT HOOKS END

  return (
    <>
      {/* Show LinearProgress Bar if isLiveSearch */}
      {isLiveSearch && (
        <Fade in={isLiveSearch} timeout={{ enter: 1000, exit: 500 }}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                  letterSpacing: 1,
                  animation: "pulse 1.5s infinite",
                }}
              >
                {((liveSearchProgress.fetched / liveSearchProgress.total) * 100).toFixed(0) < 21
                  ? "Finding more Outliers"
                  : "Measuring the Performance"}{" "}
                {((liveSearchProgress.fetched / liveSearchProgress.total) * 100).toFixed(0)}%
              </Typography>
            </Box>
            <FancyLinearProgress
              variant="determinate"
              value={(liveSearchProgress.fetched / liveSearchProgress.total) * 100}
            />
          </Box>
        </Fade>
      )}

      {/* Display search results in a grid */}
      <Grid
        container
        spacing={2}
        columns={60}
        sx={{ alignItems: "flex-start", justifyContent: "left", display: "flex", minWidth: "100%" }}
      >
        {/* Display loading placeholders if status is not idle, otherwise display hits */}
        {status !== "idle" && (!indexUiState.page || indexUiState.page === 1)
          ? Array.from({ length: HITS_PER_PAGE }).map((_, index) => (
              <Grid
                mobile={60}
                tablet={elementsPerRow ? elementsPerRow : 30}
                smallDesktop={elementsPerRow ? elementsPerRow : 20}
                mediumDesktop={elementsPerRow ? elementsPerRow : 15}
                largeDesktop={elementsPerRow ? elementsPerRow : 12}
                veryLargeDesktop={elementsPerRow ? elementsPerRow : 10}
                key={index}
              >
                <LoadingVideoPreviewPlaceholder />
              </Grid>
            ))
          : hitsToDisplay.map((hit, index) => (
              <Grid
                mobile={60}
                tablet={elementsPerRow ? elementsPerRow : 30}
                smallDesktop={elementsPerRow ? elementsPerRow : 20}
                mediumDesktop={elementsPerRow ? elementsPerRow : 15}
                largeDesktop={elementsPerRow ? elementsPerRow : 12}
                veryLargeDesktop={elementsPerRow ? elementsPerRow : 10}
                key={hit.objectID ? hit.objectID : index}
              >
                <VideoPreview
                  data={hit}
                  userState={userState}
                  favorite={favorites.includes(hit.id)}
                  enableFavoriteInteraction={!isLiveSearch}
                  addToFavorites={addToFavorites}
                  removeFromFavorites={removeFromFavorites}
                  fetchFavoritesFromFirestore={fetchFavoritesFromFirestore}
                  removeHitById={removeHitById}
                />
              </Grid>
            ))}
        <div ref={bottomSearchResultsRef} aria-hidden="true" />
      </Grid>

      {/* When scrolling down and it takes time to fetch more search results display a loading sign */}
      {(!isLastPage || status !== "idle") && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box>
      )}

      {/* Show LoadingCircle if isLiveSearch */}
      {isLiveSearch && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", m: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {/* When there are no search results found for the query display a message */}
      {!isLiveSearch && isLastPage && hitsToDisplay.length === 0 && status === "idle" && initialLoad === false && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="body2" sx={{ color: theme.palette.grey.dark }}>
            Sorry, we didn't find any videos.
          </Typography>
          {!isMobile && (
            <Typography variant="body2" sx={{ color: theme.palette.grey.dark }}>
              Please try a different search term and check your filters.
            </Typography>
          )}
        </Box>
      )}

      {/* Warning popup if maximum number of favorites has been reached */}
      <PopupWithTitleAndClose open={showWarningPopup} handleClose={() => setShowWarningPopup(false)} title={"Warning"}>
        <Typography variant="body2">
          You have reached the maximum of {MAX_FAVORITES} thumbnails you can save to your favorites. Please delete some
          before adding more.
        </Typography>
      </PopupWithTitleAndClose>
    </>
  );
}
