import { LandingPageStandard } from "components/page";
import { BlogGrid } from "features/blog";
import { CustomHelmet } from "features/helmet";

export default function BlogPage() {
  return (
    <>
      <CustomHelmet
        title={"glowAI Blog"}
        description={
          "Get the latest insights, tips and best practices on YouTube thumbnails and strategies to boost your YouTube channel."
        }
      />
      <LandingPageStandard>
        <BlogGrid />
      </LandingPageStandard>
    </>
  );
}
