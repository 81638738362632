import { Divider, Typography } from "@mui/material";
import { useIsDesktop, useIsMobile, useIsTablet } from "styles/breakpoint";
import theme from "styles/theme";

export function DrawerListItemL1({ longPageName, shortPageName, first = false }) {
  // Breakpoint
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  return isDesktop ? (
    <>
      {!first && <Divider sx={{ my: 2, borderColor: theme.palette.grey.main }} />}
      <Typography variant="body2" sx={{ fontWeight: 500, p: 1, pt: first ? 0 : 1 }}>
        {longPageName}
      </Typography>
    </>
  ) : isTablet ? (
    <>
      {!first && <Divider sx={{ my: 2, borderColor: theme.palette.grey.main }} />}
      <Typography
        variant="custom"
        sx={{ fontWeight: 600, p: 1, pt: first ? 0 : 1, textAlign: "center", fontSize: 12 }}
      >
        {shortPageName}
      </Typography>
    </>
  ) : (
    isMobile && (
      <>
        {!first && <Divider sx={{ my: 2, borderColor: theme.palette.grey.main }} />}
        <Typography variant="body2" sx={{ fontWeight: 500, p: 1, pt: first ? 0 : 1, px: 2 }}>
          {longPageName}
        </Typography>
      </>
    )
  );
}
