import { Box, Skeleton } from "@mui/material";
import { DetailsChart } from "./DetailsChart";

export function DetailsTab({ previewData, fetchedVideoData, fetchedChannelData }) {
  // Breakpoints

  const SELECT_NAMES = {
    view_count: "Views",
    like_count: "Likes",
    comment_count: "Comments",
  };

  function transformHistory(history) {
    const result = {};

    for (const [key, value] of Object.entries(history)) {
      result[SELECT_NAMES[key]] = Object.entries(value).map(([dt, count]) => ({
        dt: new Date(dt),
        value: count,
      }));
    }

    return result;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", px: 2 }}>
      {fetchedVideoData && fetchedVideoData.history && Object.keys(fetchedVideoData.history).length > 0 ? (
        <DetailsChart
          dataset={transformHistory(fetchedVideoData.history)}
          published_at={fetchedVideoData.latest.published_at}
        />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Skeleton
            variant="rectangular"
            sx={{
              width: "120",
              height: 40,
              borderRadius: 20,
              p: 0,
              mb: 1,
            }}
          />
          <Skeleton variant="rectangular" sx={{ width: "100%", height: 600, borderRadius: 5 }} />
        </Box>
      )}
    </Box>
  );
}
