import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { InstantSearch } from "react-instantsearch";
import { useIsMediumDesktop, useIsSmallDesktop, useIsTablet } from "styles/breakpoint";
import { REFRESH_MS, setupSearch } from "../../../api/typesense/config";
import { useOutliersContext } from "../../OutliersContext";
import { SearchResultsGrid } from "../../SearchResultsGrid";

export function SimilarThumbnailsTab({ userState, id }) {
  // context
  const { similarThumbnailInitialLoad, setSimilarThumbnailInitialLoad } = useOutliersContext();

  const [typesenseInstance, setTypesenseInstance] = useState({});
  const [retrievingKeys, setRetrievingKeys] = useState(false);
  const [indexName, setIndexName] = useState(process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION);

  const isTablet = useIsTablet();
  const isSmallDesktop = useIsSmallDesktop();
  const isMediumDesktop = useIsMediumDesktop();

  const customSearchParameters = {
    query: "*",
    query_by: "title,embedding",
    vector_query: `embedding:([], id:${id})`,
    exclude_fields: "embedding",
    highlight_full_fields: null,
  };

  useEffect(() => {
    // When loading the page for the first time, set up the search client
    if (similarThumbnailInitialLoad) {
      setupSearch(
        retrievingKeys,
        setRetrievingKeys,
        setTypesenseInstance,
        setSimilarThumbnailInitialLoad,
        customSearchParameters,
      );
    }

    // Refresh the search client every REFRESH_MS milliseconds to avoid the expiry of the typesense API key
    const interval = setInterval(() => {
      setupSearch(
        retrievingKeys,
        setRetrievingKeys,
        setTypesenseInstance,
        setSimilarThumbnailInitialLoad,
        customSearchParameters,
      );
    }, REFRESH_MS);

    return () => clearInterval(interval);
  }, [retrievingKeys, similarThumbnailInitialLoad]);

  return !similarThumbnailInitialLoad ? (
    <Box sx={{ width: "100%", px: 2, pt: 2 }}>
      <InstantSearch
        indexName={indexName ? indexName : process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION}
        searchClient={typesenseInstance.searchClient}
      >
        <SearchResultsGrid
          userState={userState}
          indexName={indexName ? indexName : process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION}
          elementsPerRow={isTablet ? 60 : isSmallDesktop ? 30 : isMediumDesktop ? 20 : 10}
          similarSearchForId={id}
        />
      </InstantSearch>
    </Box>
  ) : null;
}
