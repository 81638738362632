import { Avatar } from "@mui/material";
import { auth } from "config/firebase";
import { useNavigate } from "react-router-dom";
import theme from "styles/theme";

export function SignInAvatar() {
  const navigate = useNavigate();
  return (
    <Avatar
      onClick={() => navigate("/app/outliers/search")}
      sx={{
        textTransform: "none",
        height: { mobile: 30, tablet: 40 },
        width: { mobile: 30, tablet: 40 },
        fontSize: { mobile: 12, tablet: 18 },
        boxShadow: 0,
        color: theme.palette.white.main,
        bgcolor: theme.palette.primary.main,
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
          // bgcolor: buttonBackgroundColorHover,
          border: 0,
        },
      }}
    >
      {auth.currentUser.displayName[0]}
    </Avatar>
  );
}
